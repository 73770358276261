/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonUtils } from "_helpers";
import { getProjects, getDeliveries, getDeliveryDevices, getProducts, endDelivery } from "_services";
import { Delivery, DeliveryDevices, DeliveryMap } from "_pages";
import { openAlertDialog } from "../../_actions";
import Pagination from "react-js-pagination";
import i18n from "../../i18n";
import Aux from "../../hoc/_Aux";

class DeliveryManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDelivery: false,
            projectid: "A",
            productid: "A",
            deliverystatus: "A",
            activePage: 1,
            pageSize: 6,
            currentPage: 1,
            deliveries: [],
            projects: [],
            products: [],
            selectedDeliveryid: "",
            deliveryDevices: "",
            isDeliveryStatus: false,
            status: "",
            isDeliveryDevices: false,
            deliverylocations: [],
            defaultCenter: {},
            selectedDelivery: "",
        };

        this._handleSaveClose = this._handleSaveClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleSelectChange = this._handleSelectChange.bind(this);
        this._getDeliveries = this._getDeliveries.bind(this);
        this._handleDeliveryView = this._handleDeliveryView.bind(this);
        this._getProducts = this._getProducts.bind(this);
        this._handleDelivery = this._handleDelivery.bind(this);
        this._handleDeliveryStatus = this._handleDeliveryStatus.bind(this);
        this._handleStatus = this._handleStatus.bind(this);
        this._handleDeliveryDevices = this._handleDeliveryDevices.bind(this);
        this._handleDeliveryFinish = this._handleDeliveryFinish.bind(this);
    }

    _handleDeliveryFinish(deliveryid) {
        this.props.dispatch(
            openAlertDialog(
                "배송을 완료하시겠습니까?",
                "배송 완료",
                null,
                true,
                "배송 완료",
                "취소",
                () => {
                    this.props.endDelivery({ deliveryid: deliveryid }, (error, result) => {
                        if (result) {
                            this.props.dispatch(openAlertDialog("배송이 완료되었습니다", false, "check", false, null, null, null, null));
                            this._getDeliveries();
                        }
                    });
                },
                null
            )
        );
    }

    _handleDeliveryDevices(deliveryid, location, locations, data, status) {
        if (status === "M" || status === "E") {
            const center = location ? location.split("|") : "0|0";
            this.setState({
                isDeliveryDevices: true,
                selectedDeliveryid: deliveryid,
                deliverylocations: locations,
                defaultCenter: location ? { lat: parseFloat(center[0]), lng: parseFloat(center[1]) } : { lat: 40.73, lng: -73.93 },
                selectedDelivery: data,
            });
        }
    }

    _handleDeliveryStatus(deliveryid, status) {
        if (status === "M") {
            this.setState({
                isDeliveryStatus: !this.state.isDeliveryStatus,
                selectedDeliveryid: deliveryid,
            });
        }
    }

    _handleStatus(e) {
        this.setState(
            {
                deliverystatus: e.target.value,
            },
            () => {
                this._getDeliveries();
            }
        );
    }

    _handleDelivery() {
        this.setState({
            ...this.state,
            isDelivery: !this.state.isDelivery,
            selectedDeliveryid: "",
        });
    }

    _handleSaveClose() {
        this.setState({
            ...this.state,
            isDelivery: !this.state.isDelivery,
            selectedDeliveryid: "",
        });
        this._getDeliveries();
    }

    _handleDeliveryView(deliveryid) {
        this.setState({
            ...this.state,
            isDelivery: !this.state.isDelivery,
            selectedDeliveryid: deliveryid,
        });
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            [name]: value,
        });
    }

    _handleSelectChange(e, searchFlag) {
        this.setState(
            {
                ...this.state,
                [searchFlag]: e.target.value,
                productid: searchFlag === "projectid" ? "A" : e.target.value,
            },
            () => {
                this._getDeliveries();
                if (searchFlag === "projectid") this._getProducts();
            }
        );
    }

    _handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,
            currentPage: pageNumber,
        });
    }

    componentDidMount() {
        this._getDeliveries();
        this._getProjects();
    }

    _getDeliveries() {
        const { projectid, productid, deliverystatus } = this.state;
        this.props.getDeliveries(
            {
                projectid: projectid,
                productid: productid,
                deliverystatus: deliverystatus,
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        deliveries: result,
                    });
                }
            }
        );
    }

    _getProjects() {
        this.props.getProjects({ searchStr: "" }, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    projects: result,
                });
            }
        });
    }

    _getProducts() {
        const { projectid } = this.state;
        this.props.getProducts(
            {
                searchStr: "",
                projectid: projectid,
                categoryid: "A",
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        products: result,
                    });
                }
            }
        );
    }
    render() {
        const apipaginate = commonUtils.dataPaging(this.state.deliveries, this.state.currentPage, this.state.pageSize);
        return (
            <Aux>
                <div className="main_content">
                    <div className="page_content">
                        <div className="container-fluid">
                            {/* <div className="row">
                                <div className="col-12">
                                    <div className="page_title_box">
                                        <h4>배송 현황</h4>
                                    </div>
                                </div>
                            </div> */}

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="form_box">
                                                        <div className="form-group">
                                                            <select
                                                                className="form-control"
                                                                name="projectid"
                                                                value={this.state.projectid}
                                                                onChange={(e) => this._handleSelectChange(e, "projectid")}
                                                            >
                                                                <option value="A">프로젝트 선택</option>
                                                                {this.state.projects &&
                                                                    this.state.projects.map((data) => (
                                                                        <option key={data.projectid} value={data.projectid}>
                                                                            {data.name}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                        &nbsp;&nbsp;
                                                        <div className="form-group">
                                                            <select
                                                                className="form-control"
                                                                name="productid"
                                                                value={this.state.productid}
                                                                onChange={(e) => this._handleSelectChange(e, "productid")}
                                                            >
                                                                <option value="A">상품 선택</option>
                                                                {this.state.products &&
                                                                    this.state.products.map((data) => (
                                                                        <option key={data.productid} value={data.productid}>
                                                                            {data.name}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                        &nbsp;&nbsp;
                                                        <div className="form-group">
                                                            <select
                                                                className="form-control"
                                                                name="deliverystatus"
                                                                value={this.state.deliverystatus}
                                                                onChange={(e) => this._handleStatus(e)}
                                                            >
                                                                <option value="A">배송 상태 선택</option>
                                                                <option value="0">배송 등록</option>
                                                                <option value="M">배송 중</option>
                                                                <option value="E">배송 완료</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="text-sm-right">
                                                        <button type="button" className="btn btn-primary" onClick={this._handleDelivery}>
                                                            <i className="bx bx-plus"></i> 배송 시작하기
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table table-sm table-centered table-nowrap mb-0">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col" className="text-center">
                                                                배송 ID
                                                            </th>
                                                            <th scope="col" className="text-center">
                                                                프로젝트
                                                            </th>
                                                            <th scope="col" className="text-center">
                                                                {i18n.t("label_product_nm")}
                                                            </th>
                                                            <th scope="col" className="text-center">
                                                                담당자
                                                            </th>
                                                            <th scope="col" className="text-center">
                                                                위치
                                                            </th>
                                                            <th scope="col" className="text-center" style={{ width: "100px" }}>
                                                                디바이스 수
                                                            </th>

                                                            <th scope="col" className="text-center">
                                                                {i18n.t("label_shippingaddress")}
                                                            </th>

                                                            <th scope="col" className="text-center">
                                                                {i18n.t("label_status")}
                                                            </th>
                                                            <th scope="col" className="text-center" style={{ width: "150px" }}>
                                                                배송시작일
                                                            </th>
                                                            <th scope="col" className="text-center" style={{ width: "150px" }}>
                                                                배송완료일
                                                            </th>
                                                            <th scope="col" className="text-center" style={{ width: "80px" }}>
                                                                배송 완료
                                                            </th>
                                                            {/* <th scope="col" className="text-center" style={{ width: "150px" }}>
                                                                {i18n.t("label_created")}
                                                            </th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.deliveries &&
                                                            apipaginate.map((data) => (
                                                                <tr
                                                                    className={
                                                                        "col-12" + (this.state.selectedDeliveryid === data.deliveryid ? " selected" : "")
                                                                    }
                                                                    key={data.deliveryid}
                                                                >
                                                                    <td
                                                                        className="text-center pointer"
                                                                        onClick={() =>
                                                                            this._handleDeliveryDevices(
                                                                                data.deliveryid,
                                                                                data.location,
                                                                                data.locations,
                                                                                data,
                                                                                data.status
                                                                            )
                                                                        }
                                                                    >
                                                                        {data.deliveryid}
                                                                    </td>
                                                                    <td
                                                                        className="text-center pointer"
                                                                        onClick={() =>
                                                                            this._handleDeliveryDevices(
                                                                                data.deliveryid,
                                                                                data.location,
                                                                                data.locations,
                                                                                data,
                                                                                data.status
                                                                            )
                                                                        }
                                                                    >
                                                                        {data.projectname}
                                                                    </td>
                                                                    <td
                                                                        className="text-center pointer"
                                                                        onClick={() =>
                                                                            this._handleDeliveryDevices(
                                                                                data.deliveryid,
                                                                                data.location,
                                                                                data.locations,
                                                                                data,
                                                                                data.status
                                                                            )
                                                                        }
                                                                    >
                                                                        {data.productname}
                                                                    </td>
                                                                    <td
                                                                        className="text-center pointer"
                                                                        onClick={() =>
                                                                            this._handleDeliveryDevices(
                                                                                data.deliveryid,
                                                                                data.location,
                                                                                data.locations,
                                                                                data,
                                                                                data.status
                                                                            )
                                                                        }
                                                                    >
                                                                        {data.chargername}
                                                                    </td>
                                                                    <td
                                                                        className="text-center pointer"
                                                                        onClick={() =>
                                                                            this._handleDeliveryDevices(
                                                                                data.deliveryid,
                                                                                data.location,
                                                                                data.locations,
                                                                                data,
                                                                                data.status
                                                                            )
                                                                        }
                                                                    >
                                                                        {data.location && parseFloat(data.location.split("|")[0]).toFixed("6") + " , "}
                                                                        {data.location && parseFloat(data.location.split("|")[1]).toFixed("6")}
                                                                        {/* {commonUtils.dataReplace(data.location, "|", ",")} */}
                                                                    </td>
                                                                    <td
                                                                        className="text-center pointer"
                                                                        onClick={() =>
                                                                            this._handleDeliveryDevices(
                                                                                data.deliveryid,
                                                                                data.location,
                                                                                data.locations,
                                                                                data,
                                                                                data.status
                                                                            )
                                                                        }
                                                                    >
                                                                        {data.devicecnt}
                                                                    </td>
                                                                    <td
                                                                        className="text-center pointer"
                                                                        onClick={() =>
                                                                            this._handleDeliveryDevices(
                                                                                data.deliveryid,
                                                                                data.location,
                                                                                data.locations,
                                                                                data,
                                                                                data.status
                                                                            )
                                                                        }
                                                                    >
                                                                        {data.shippingaddress}
                                                                    </td>
                                                                    <td className="text-center pointer">
                                                                        <button
                                                                            type="button"
                                                                            className={"btn btn_progress btn-sm status-" + data.status}
                                                                            onClick={() =>
                                                                                this._handleDeliveryDevices(
                                                                                    data.deliveryid,
                                                                                    data.location,
                                                                                    data.locations,
                                                                                    data,
                                                                                    data.status
                                                                                )
                                                                            }
                                                                        >
                                                                            {i18n.t("label_delivery_status_" + data.status)}
                                                                            {data.status === "M" && <span style={{ padding: "0 0 0 10px" }}>모니터링</span>}
                                                                        </button>
                                                                    </td>
                                                                    <td className="text-center" style={{ fontFamily: "verdana", fontSize: "12px" }}>
                                                                        {commonUtils.dateFormat(data.starttime)}
                                                                    </td>
                                                                    <td className="text-center" style={{ fontFamily: "verdana", fontSize: "12px" }}>
                                                                        {commonUtils.dateFormat(data.endtime)}
                                                                    </td>
                                                                    <td className="text-center">
                                                                        {data.status === "M" && (
                                                                            <button
                                                                                type="button"
                                                                                className={
                                                                                    "btn btn_progress btn-sm " + (data.status === "0" ? "status-0" : "status-A")
                                                                                }
                                                                                onClick={() => this._handleDeliveryFinish(data.deliveryid)}
                                                                            >
                                                                                {data.status === "M" && "배송 완료"}
                                                                            </button>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                                {this.state.deliveries.length > 0 && (
                                                    <div className="data_page">
                                                        <Pagination
                                                            activePage={this.state.activePage}
                                                            itemsCountPerPage={6}
                                                            totalItemsCount={this.state.deliveries.length}
                                                            pageRangeDisplayed={10}
                                                            onChange={this._handlePageChange.bind(this)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.state.isDeliveryDevices && this.state.deliverylocations.length > 0 && (
                                <DeliveryMap
                                    deliveryid={this.state.selectedDeliveryid}
                                    locations={this.state.deliverylocations}
                                    defaultCenter={this.state.defaultCenter}
                                />
                            )}
                            {this.state.isDeliveryDevices && (
                                <DeliveryDevices
                                    deliveryid={this.state.selectedDeliveryid}
                                    locations={this.state.deliverylocations}
                                    defaultCenter={this.state.defaultCenter}
                                    deliveryInfo={this.state.selectedDelivery}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {this.state.isDelivery && (
                    <Delivery
                        open={this.state.isDelivery}
                        onClose={this._handleDelivery}
                        onSave={this._handleSaveClose}
                        deliveryid={this.state.selectedDeliveryid}
                        projects={this.state.projects}
                        projectid={this.state.projectid}
                        productid={this.state.productid}
                    />
                )}
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getDeliveries,
            getProjects,
            getDeliveryDevices,
            getProducts,
            endDelivery,
            dispatch,
        },
        dispatch
    );

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(DeliveryManagement);
export { connectedPage as DeliveryManagement };
// export default connect(mapStateToProps, mapDispatchToProps)(windowSize(DeliveryManagement));
