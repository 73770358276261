import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { routes } from "./routes";
import { AppHeader } from "./AppHeader";
import { AppMenu } from "./AppMenu";
import { getMenus, getSuperMenus } from "./menus";
export class AppLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleExtendGnb: false,
            toggleExtendMenu: false,
        };
    }

    render() {
        return (
            <div id="layout-wrapper">
                <AppHeader {...this.props}></AppHeader>

                <AppMenu {...this.props} menus={getMenus()} supermenus={getSuperMenus()}></AppMenu>
                <Switch>
                    {routes.map((route, idx) => {
                        return route.component ? (
                            <Route key={"key"} path={route.path} exact={route.exact} render={(props) => <route.component {...props} />} />
                        ) : null;
                    })}
                </Switch>
            </div>
        );
    }
}
