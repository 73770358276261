import { requestHeaders, requestBody, handleResponse } from "_helpers";
import { getProductAction, setProductAction } from "../_actions";

export function insertProduct(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const body = params;
        const requestOptions = {
            method: "POST",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
            body: requestBody(body),
        };
        return fetch(`/apis/admin/products`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setProductAction(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function updateProduct(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const body = params;
        const requestOptions = {
            method: "PUT",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
            body: requestBody(body),
        };
        return fetch(`/apis/admin/products`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setProductAction(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function deleteProduct(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const body = params;
        const requestOptions = {
            method: "DELETE",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
            body: requestBody(body),
        };
        return fetch(`/apis/admin/products?productid=${params.productid}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setProductAction(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getProducts(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
        };
        return fetch(`/apis/admin/products?searchStr=${params.searchStr}&projectid=${params.projectid}&categoryid=${params.categoryid}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getProductAction(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getCategories(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
        };
        return fetch(`/apis/admin/categories`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getProductAction(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getDeliveries(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
        };
        return fetch(
            `/apis/admin/deliveries?projectid=${params.projectid}&productid=${params.productid}&deliverystatus=${params.deliverystatus}`,
            requestOptions
        )
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getProductAction(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function setDeliveryDeviceMatch(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const requestOptions = {
            method: "POST",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
            body: requestBody(params.devices),
        };
        return fetch(`/apis/admin/deliveries/${params.deliveryid}/device`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setProductAction(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getDeliveryRamainDevices(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
        };
        return fetch(`/apis/admin/deliveries/device?searchStr=${params.searchStr}&sensortype=${params.sensortype}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getProductAction(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getDeliveryDevices(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
        };
        return fetch(`/apis/admin/deliveries/${params.deliveryid}/device`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getProductAction(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function deleteDeliveryDevice(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const body = params;
        const requestOptions = {
            method: "DELETE",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
        };
        return fetch(`/apis/admin/deliveries/${params.deliveryid}/device/${params.deviceid}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setProductAction(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}
