/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Pagination from "react-js-pagination";
import { commonUtils } from "_helpers";
import { getProjects } from "_services";
import { Project, Products } from "_pages";
import i18n from "../../i18n";
// import { openAlertDialog } from "../_actions";
// import { Constants } from "../_constants/constants";

import Aux from "../../hoc/_Aux";

class ProjectManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isProject: false,
            isProduct: false,
            searchStr: "",
            activePage: 1,
            pageSize: 14,
            currentPage: 1,
            projects: [],
            selectedProject: "",
            selectedProjectid: "",
            chargername: "",
        };
        this._handleProject = this._handleProject.bind(this);
        this._handleSaveClose = this._handleSaveClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._getProjects = this._getProjects.bind(this);
        this._handleProducts = this._handleProducts.bind(this);
        this._handleProduct = this._handleProduct.bind(this);
    }

    _handleProducts(projectid, chargername) {
        this.setState({
            ...this.state,
            isProduct: !this.state.isProduct,
            selectedProjectid: projectid,
            chargername: chargername,
        });
    }

    _handleProduct() {
        this.setState({
            ...this.state,
            isProduct: !this.state.isProduct,
            selectedProjectid: "",
            chargername: "",
        });
    }

    _handleProjectView(project) {
        this.setState({
            ...this.state,
            isProject: !this.state.isProject,
            selectedProject: project,
        });
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            [name]: value,
        });
    }

    _handleSaveClose() {
        this._getProjects();
        this._handleProject();
    }

    _handleProject() {
        this.setState({
            ...this.state,
            isProject: !this.state.isProject,
            selectedProject: "",
        });
    }

    _handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,
            currentPage: pageNumber,
        });
    }
    componentDidMount() {
        this._getProjects();
    }

    _getProjects() {
        this.props.getProjects({ searchStr: "" }, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    projects: result,
                });
            }
        });
    }
    render() {
        const apipaginate = commonUtils.dataPaging(this.state.projects, this.state.currentPage, this.state.pageSize);
        return (
            <Aux>
                <div className="main_content">
                    <div className="page_content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page_title_box">
                                        <h4>프로젝트 현황</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="form_box">
                                                        <div className="input-group" style={{ width: "300px" }}>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="프로젝트 명"
                                                                name="searchStr"
                                                                value={this.state.searchStr}
                                                                onChange={this._handleChange}
                                                            />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-secondary btn_ico" type="button" onClick={this._getCustomers}>
                                                                    <i className="bx bx-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="text-sm-right" style={{ paddingBottom: "10px" }}>
                                                        <button type="button" className="btn btn-primary" onClick={this._handleProject}>
                                                            <i className="bx bx-plus"></i> 프로젝트 추가
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table table-centered table-nowrap mb-0">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">no.</th>
                                                            <th scope="col">프로젝트 명</th>
                                                            <th scope="col">상품 수</th>
                                                            <th scope="col" style={{ width: "180px" }}>
                                                                상품 명
                                                            </th>
                                                            <th scope="col"></th>
                                                            <th scope="col">총 디바이스 수</th>
                                                            <th scope="col">담당자</th>
                                                            <th scope="col">{i18n.t("label_created")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.projects &&
                                                            apipaginate.map((data, index) => (
                                                                <tr className="col-12" key={data.projectid}>
                                                                    <td>{index + 1}</td>
                                                                    <td>
                                                                        <span
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => {
                                                                                this._handleProjectView(data);
                                                                            }}
                                                                        >
                                                                            {data.name}
                                                                        </span>
                                                                    </td>
                                                                    <td style={{ fontFamily: "verdana" }}>{data.productcnt}</td>
                                                                    <td>{data.productname}</td>
                                                                    <td>
                                                                        {data.productcnt > 1 && (
                                                                            <button
                                                                                className="btn btn-info"
                                                                                style={{ textAlign: "right", fontSize: "12px" }}
                                                                                onClick={() => this._handleProducts(data.projectid, data.chargername)}
                                                                            >
                                                                                ...
                                                                            </button>
                                                                        )}
                                                                    </td>
                                                                    <td style={{ fontFamily: "verdana" }}>{data.devicecnt}</td>
                                                                    <td>{data.chargername}</td>
                                                                    <td style={{ fontFamily: "verdana" }}>{data.createtime}</td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                                {this.state.projects.length > 0 && (
                                                    <div className="data_page">
                                                        <Pagination
                                                            activePage={this.state.activePage}
                                                            itemsCountPerPage={14}
                                                            totalItemsCount={this.state.projects.length}
                                                            pageRangeDisplayed={10}
                                                            onChange={this._handlePageChange.bind(this)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isProject && (
                    <Project open={this.state.isProject} onClose={this._handleProject} onSave={this._handleSaveClose} project={this.state.selectedProject} />
                )}
                {this.state.isProduct && (
                    <Products
                        open={this.state.isProduct}
                        onClose={this._handleProduct}
                        projectid={this.state.selectedProjectid}
                        chargername={this.state.chargername}
                    />
                )}
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getProjects,
            dispatch,
        },
        dispatch
    );

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(ProjectManagement);
export { connectedPage as ProjectManagement };
// export default connect(mapStateToProps, mapDispatchToProps)(windowSize(CustomerManagement));
