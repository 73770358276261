import { requestHeaders, requestBody, handleResponse, commonUtils } from "_helpers";
import { gerUsersReq, setUserReq, userLoginReq } from "../_actions";
import moment from "moment";

// 로그인
export function login(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const body = {
            userid: params.userid,
            password: params.password,
        };
        const requestOptions = {
            method: "POST",
            headers: requestHeaders({ "Content-Type": "application/json" }),
            body: requestBody(body),
        };

        return fetch(`/apis/admin/users/login`, requestOptions)
            .then((response) => handleResponse(response, true))
            .then((response) => {
                const loginSession = {
                    userid: response.result.userid,
                    name: response.result.name,
                    phone: response.result.phone,
                    email: response.result.email,
                };
                setLoginSession(loginSession);
                callback(null, response.result);
                dispatch(userLoginReq(loginSession));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function insertUser(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const body = params;
        const requestOptions = {
            method: "POST",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
            body: requestBody(body),
        };
        return fetch(`/apis/admin/users`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setUserReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function updateUser(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const body = params;
        const requestOptions = {
            method: "PUT",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
            body: requestBody(body),
        };
        return fetch(`/apis/admin/users`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setUserReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function deleteUser(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const body = params;
        const requestOptions = {
            method: "DELETE",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
            body: requestBody(body),
        };
        return fetch(`/apis/admin/users?userid=${params.userid}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setUserReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getUsers(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
        };
        return fetch(`/apis/admin/users?searchStr=${params.searchStr}&projectid=${params.projectid}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(gerUsersReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function logout() {
    return (dispatch) => {
        localStorage.clear();
    };
}

export function checkLogin() {
    const now = moment();
    const lastSessionTimeStr = localStorage.getItem("last-session-time");
    if (lastSessionTimeStr) {
        const lastSessionTimeDate = new Date(lastSessionTimeStr);
        const lastSessionTime = moment(lastSessionTimeDate.toISOString());
        const duration = Number(moment.duration(now - lastSessionTime).asMinutes());
        const sessionTimeout = commonUtils.isValidNumber(`${process.env.REACT_APP_SESSION_EXPIRED_TIME}`)
            ? Number(`${process.env.REACT_APP_SESSION_EXPIRED_TIME}`)
            : 0;
        if (sessionTimeout === 0 || duration < sessionTimeout) {
            localStorage.setItem("last-session-time", moment().toDate());
            return true;
        }
    }
    //logout();
    return false;
}

function setLoginSession(loginSession) {
    localStorage.setItem("session", JSON.stringify(loginSession));
    localStorage.setItem("last-session-time", moment().toDate());
    //localStorage.setItem("time-zone", moment.tz.guess());
}
