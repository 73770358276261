/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Pagination from "react-js-pagination";
import { commonUtils } from "_helpers";
import { getProducts, getProjects } from "_services";
import { Product } from "_pages";
import i18n from "../../i18n";

import Aux from "../../hoc/_Aux";

class ProductManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isProduct: false,
            searchStr: "",
            projectid: "A",
            categoryid: "A",
            activePage: 1,
            pageSize: 14,
            currentPage: 1,
            products: [],
            projects: [],
            categories: [],
            selectedProduct: "",
        };
        this._handleProduct = this._handleProduct.bind(this);
        this._handleSaveClose = this._handleSaveClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleSelectChange = this._handleSelectChange.bind(this);
        this._getProducts = this._getProducts.bind(this);
        this._handleProductView = this._handleProductView.bind(this);
    }
    _handleProductView(product) {
        this.setState({
            ...this.state,
            isProduct: !this.state.isProduct,
            selectedProduct: product,
        });
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            [name]: value,
        });
    }

    _handleSelectChange(e, searchFlag) {
        this.setState(
            {
                ...this.state,
                [searchFlag]: e.target.value,
            },
            () => {
                this._getProducts();
            }
        );
    }

    _handleSaveClose() {
        this._getProducts();
        this._handleProduct();
    }

    _handleProduct() {
        this.setState({
            ...this.state,
            isProduct: !this.state.isProduct,
            selectedProduct: "",
        });
    }
    _handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,
            currentPage: pageNumber,
        });
    }
    componentDidMount() {
        this._getProducts();
        this._getProjects();
    }

    _getProducts() {
        const { projectid, categoryid, searchStr } = this.state;
        this.props.getProducts(
            {
                searchStr: searchStr,
                projectid: projectid,
                categoryid: categoryid,
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        products: result,
                    });
                }
            }
        );
    }
    _getProjects() {
        this.props.getProjects({ searchStr: "" }, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    projects: result,
                });
            }
        });
    }

    render() {
        const apipaginate = commonUtils.dataPaging(this.state.products, this.state.currentPage, this.state.pageSize);
        return (
            <Aux>
                <div className="main_content">
                    <div className="page_content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page_title_box">
                                        <h4>{i18n.t("menu_product_mng")}</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="form_box">
                                                        <div className="form-group">
                                                            <select
                                                                className="form-control"
                                                                name="projectid"
                                                                value={this.state.projectid}
                                                                onChange={(e) => this._handleSelectChange(e, "projectid")}
                                                            >
                                                                <option value="A">프로젝트 선택</option>
                                                                {this.state.projects &&
                                                                    this.state.projects.map((data) => (
                                                                        <option key={data.projectid} value={data.projectid}>
                                                                            {data.name}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                        &nbsp;&nbsp;
                                                        <div className="input-group" style={{ width: "300px" }}>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={i18n.t("label_product_nm")}
                                                                name="searchStr"
                                                                value={this.state.searchStr}
                                                                onChange={this._handleChange}
                                                            />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-primary btn_ico" type="button" onClick={this._getProducts}>
                                                                    <i className="bx bx-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="text-sm-right">
                                                        <button type="button" className="btn btn-primary" onClick={this._handleProduct}>
                                                            <i className="bx bx-plus"></i> {i18n.t("label_btn_addproduct")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table table-centered table-nowrap mb-0">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">상품코드</th>
                                                            <th scope="col">상품명</th>
                                                            <th scope="col">프로젝트</th>
                                                            <th scope="col">디바이스 수</th>
                                                            {/* <th scope="col">상태</th> */}
                                                            <th scope="col">메모</th>
                                                            <th scope="col">담당자</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.products &&
                                                            apipaginate.map((data) => (
                                                                <tr className="col-12" key={data.productid}>
                                                                    <td>{data.code}</td>
                                                                    <td>
                                                                        <span
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => {
                                                                                this._handleProductView(data);
                                                                            }}
                                                                        >
                                                                            {data.name}
                                                                        </span>
                                                                    </td>
                                                                    <td>{data.projectnm}</td>
                                                                    <td>{data.devicecnt}</td>
                                                                    {/* <td>{data.status}</td> */}
                                                                    <td>{data.memo}</td>
                                                                    <td>{data.chargername}</td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                                {this.state.products.length > 0 && (
                                                    <div className="data_page">
                                                        <Pagination
                                                            activePage={this.state.activePage}
                                                            itemsCountPerPage={14}
                                                            totalItemsCount={this.state.products.length}
                                                            pageRangeDisplayed={10}
                                                            onChange={this._handlePageChange.bind(this)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.isProduct && (
                    <Product
                        open={this.state.isProduct}
                        onClose={this._handleProduct}
                        onSave={this._handleSaveClose}
                        projects={this.state.projects}
                        categories={this.state.categories}
                        projectid={this.state.projectid}
                        categoryid={this.state.categoryid}
                        product={this.state.selectedProduct}
                    />
                )}
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getProducts,
            getProjects,
            dispatch,
        },
        dispatch
    );

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(ProductManagement);
export { connectedPage as ProductManagement };
// export default connect(mapStateToProps, mapDispatchToProps)(windowSize(ProductManagement));
