import { checkLogin } from "../_services";
import { userLogout, openAlertDialog } from "../_actions";

export function requestHeaders(headers) {
    const session = JSON.parse(localStorage.getItem("session"));
    return Object.assign({}, headers, {
        Authorization: session ? session.Authorization : "",
    });
}

export function requestBody(body) {
    //console.log("Request body : " + JSON.stringify(body));
    return JSON.stringify(body);
}

export function handleResponse(response, excludeCheckLogin) {
    if (!excludeCheckLogin && !checkLogin()) {
        window.location.href = "/dashboard";
    }

    return response.text().then((text) => {
        if (!response.ok) {
            text = escapeSpecialChars(text);
            const data = text && JSON.parse(text);
            console.log("HTTP Error : " + text);
            window.fetch.dispatch(openAlertDialog(data.error.errorMessage));
            return Promise.reject(data.error);
        } else {
            text = escapeSpecialChars(text);
            const data = text && JSON.parse(text);
            // 200이 아니면 에러
            if (data.code === 200) {
                //console.log("API Result : " + text);
                return data.data;
            } else if (data.code === 401) {
                window.fetch.dispatch(userLogout());
                if (typeof window.fetch.dispatch === "function") {
                    window.fetch.dispatch(
                        openAlertDialog(data.error.errorMessage, false, null, null, null, () => {
                            window.location.href = "/dashboard";
                        })
                    );
                } else {
                    alert(data.message);
                    window.location.href = "/dashboard";
                }
                return {};
            } else {
                console.log("API Error : " + text);
                window.fetch.dispatch(openAlertDialog(data.error.errorMessage));
                return Promise.reject(data.error);
            }
        }
    });
}

var escapeSpecialChars = function (value) {
    return value.replace(/\n/g, "\\n").replace(/'/g, '"'); // ' 가 JSON 파싱 에러를 발생시켜 " 로 치환함 (single quote to double quote)
    //.replace(/\"/g, '\\"')
    //.replace(/\&/g, "\\&")
    //.replace(/\r/g, "\\r")
    //.replace(/\t/g, "\\t")
    //.replace(/\b/g, "\\b")
    //.replace(/\f/g, "\\f");
};
