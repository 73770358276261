/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonUtils } from "_helpers";
import { getProjects, getDeliveries, getDeliveryDevices, getDeviceStatusLogs } from "_services";
import { DeliveryDevices } from "_pages";
import i18n from "../../i18n";
import Aux from "../../hoc/_Aux";

class DeliveryMonitoring extends Component {
    constructor(props) {
        super(props);

        this.state = {
            projectid: "A",
            projectname: "",
            chargername: "",
            chargerphone: "",
            chargerposition: "",
            deliverystatus: "A",
            selectedDeliveryid: "",
            selectedDelivery: "",
            isDevices: false,
        };

        this._handleSelectChange = this._handleSelectChange.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._getDeliveries = this._getDeliveries.bind(this);
        this._handleChangeProject = this._handleChangeProject.bind(this);
        this._handleDevices = this._handleDevices.bind(this);
    }
    _handleDevices(data) {
        this.setState({
            ...this.state,
            isDevices: true,
            selectedDelivery: data,
            selectedDeliveryid: data.deliveryid,
        });

        window.scrollTo(0, 0);
    }
    _handleChangeProject(e) {
        let data = JSON.parse(e.target.value);

        this.setState(
            {
                ...this.state,
                projectname: data.name,
                projectid: data.projectid,
                chargername: data.chargername,
                chargerphone: data.chargerphone,
                chargerposition: data.chargerposition,
            },
            () => {
                this._getDeliveries();
            }
        );
    }
    _handleSelectChange(e, searchFlag) {
        this.setState(
            {
                ...this.state,
                [searchFlag]: e.target.value,
            },
            () => {
                this._getDeliveries();
            }
        );
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            [name]: value,
        });
    }

    componentDidMount() {
        this._getDeliveries();
        this._getProjects();
    }

    _getDeliveries() {
        const { projectid, deliverystatus } = this.state;
        this.props.getDeliveries(
            {
                projectid: projectid,
                deliverystatus: deliverystatus,
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        deliveries: result,
                    });
                }
            }
        );
    }
    _getProjects() {
        this.props.getProjects({ searchStr: "" }, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    projects: result,
                });
            }
        });
    }

    render() {
        return (
            <Aux>
                <div className="main_content">
                    <div className="page_content">
                        <div className="container-fluid">
                            {/* <div className="row">
                                <div className="col-12">
                                    <div className="page_title_box">
                                        <h4>{i18n.t("menu_product_monitoring")}</h4>
                                    </div>
                                </div>
                            </div> */}

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="form_box">
                                                        <div className="form-group">
                                                            <select
                                                                className="form-control"
                                                                name="projectid"
                                                                value={this.state.projectid}
                                                                onChange={(e) => this._handleChangeProject(e)}
                                                            >
                                                                <option value="A">{i18n.t("label_custome_select")}</option>
                                                                {this.state.projects &&
                                                                    this.state.projects.map((data) => (
                                                                        <option key={data.projectid} value={JSON.stringify(data)}>
                                                                            {data.name}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                        &nbsp;
                                                        <div className="form-group">
                                                            <select
                                                                className="form-control"
                                                                name="deliverystatus"
                                                                value={this.state.deliverystatus}
                                                                onChange={(e) => this._handleSelectChange(e, "deliverystatus")}
                                                            >
                                                                <option value="A">{i18n.t("label_delivery_status")}</option>
                                                                <option value="0">등록</option>
                                                                <option value="M">배송중</option>
                                                                <option value="E">배송완료</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-4">
                                                    {this.state.projectname && (
                                                        <div className="widget widget-stats bg-blue">
                                                            <div className="stats-icon stats-icon-lg">
                                                                <i className="fa fa-sitemap fa-fw"></i>
                                                            </div>

                                                            <div className="stats-content">
                                                                <div className="stats-number">{this.state.projectname}</div>

                                                                <div className="stats-desc mb-1">금일 예정 업무 : 10개</div>
                                                                <div className="stats-desc mb-1">현재 진행 업무 : 5개</div>
                                                                <div className="stats-desc">
                                                                    담당자 : {this.state.chargername} / {this.state.chargerposition} / {this.state.chargerphone}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {this.state.deliveries &&
                                                        this.state.deliveries.map((data) => (
                                                            <div
                                                                className={
                                                                    "card border-0 text-truncate mb-3 text-white " +
                                                                    (data.status === "E" ? "bg-default" : data.status === "M" ? "bg-dark" : "bg-dark")
                                                                }
                                                                key={data.deliveryid}
                                                            >
                                                                <div className="card-body">
                                                                    <div
                                                                        className="mb-3 text-grey"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => this._handleDevices(data)}
                                                                    >
                                                                        <b className="mb-3">
                                                                            <span
                                                                                className={
                                                                                    data.status === "E"
                                                                                        ? "label label-danger"
                                                                                        : data.status === "M"
                                                                                        ? "label label-secondary"
                                                                                        : data.status === "S"
                                                                                        ? "label label-green"
                                                                                        : "label label-primary"
                                                                                }
                                                                            >
                                                                                {i18n.t("label_delivery_status_" + data.status)}
                                                                            </span>
                                                                            &nbsp; <span style={{ color: "white" }}>[{data.projectname}]</span>{" "}
                                                                            {data.productname} &nbsp; {data.deliveryid}
                                                                        </b>
                                                                    </div>

                                                                    <hr className="bg-white-transparent-2 m-t-10 m-b-10"></hr>

                                                                    <div className="d-flex mb-2">
                                                                        <div className="d-flex align-items-center f-s-12">
                                                                            <i className="fa fa-circle text-red f-s-8 mr-2"></i>
                                                                            상품코드
                                                                        </div>
                                                                        <div className="d-flex align-items-center ml-auto">
                                                                            <div className="width-50 text-right pl-2 f-w-600 f-s-12">
                                                                                <span>{data.productcode}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="d-flex mb-2">
                                                                        <div className="d-flex align-items-center f-s-12">
                                                                            <i className="fa fa-circle text-warning f-s-8 mr-2"></i>
                                                                            센서타입
                                                                        </div>
                                                                        <div className="d-flex align-items-center ml-auto">
                                                                            <div className="width-50 text-right pl-2 f-w-600 f-s-12">
                                                                                <span>{data.sensortype}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="d-flex">
                                                                        <div className="d-flex align-items-center f-s-12">
                                                                            <i className="fa fa-circle text-lime f-s-8 mr-2"></i>
                                                                            수량
                                                                        </div>
                                                                        <div className="d-flex align-items-center ml-auto">
                                                                            <div className="width-50 text-right pl-2 f-w-600 f-s-12">
                                                                                <span>{data.quantity} box</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <hr className="bg-white-transparent-2 m-t-10 m-b-10"></hr>
                                                                    <div>
                                                                        {commonUtils.splitArray(data.progress).map((data, index) => (
                                                                            <div style={{ display: "inline-block" }} key={index}>
                                                                                <span
                                                                                    className={
                                                                                        "label label-delivery-process " +
                                                                                        (data === "0"
                                                                                            ? "label-green"
                                                                                            : data === "S"
                                                                                            ? "label-primary"
                                                                                            : data === "M"
                                                                                            ? "label-secondary"
                                                                                            : "label-danger")
                                                                                    }
                                                                                >
                                                                                    {data === "0" ? "W" : data}
                                                                                </span>
                                                                                {data !== "E" && (
                                                                                    <i
                                                                                        className="fas fa-lg fa-fw m-r-10 fa-angle-right"
                                                                                        style={{ fontSize: "12px" }}
                                                                                    ></i>
                                                                                )}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                                {this.state.selectedDeliveryid && (
                                                    <DeliveryDevices deliveryid={this.state.selectedDeliveryid} deliveryInfo={this.state.selectedDelivery} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getDeliveries,
            getProjects,
            getDeliveryDevices,
            getDeviceStatusLogs,
            dispatch,
        },
        dispatch
    );

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(DeliveryMonitoring);
export { connectedPage as DeliveryMonitoring };
// export default connect(mapStateToProps, mapDispatchToProps)(windowSize(ProductDeviceManagement));
