/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import { commonUtils } from "_helpers";
import GoogleMapReact from "google-map-react";
import Aux from "../../hoc/_Aux";
// import { syncTranslationWithStore } from "react-redux-i18n";

class DeliveryMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deliveryid: "",
            mapsLoaded: false,
            map: null,
            maps: null,
            locations: [],
            deliveryDuration: "",
            deliverylocations: [],
            center: { lat: 40.73, lng: -73.93 },
            markerLoc: "",
            defaultCenter: "",
            defaultZoom: 14,
        };
    }

    onMapLoaded(map, maps) {
        this.googleMapRef = map;
        this.googleRef = maps;

        const delilocations = [];
        for (let marker of this.state.locations) {
            if (marker.latitude && marker.longitude) {
                delilocations.push({ status: marker.status, lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) });
            }
        }
        this.setState(
            {
                ...this.state,
                mapsLoaded: true,
                map: map,
                maps: maps,
                deliverylocations: delilocations,
            },
            () => {
                this.afterMapLoadChanges(this.state.map, this.state.maps);
            }
        );
    }

    afterMapLoadChanges(map, maps) {
        this.state.deliverylocations.map((marker) => {
            new maps.Marker({
                position: { lat: parseFloat(marker.lat), lng: parseFloat(marker.lng) },
                map,
                icon: {
                    path: maps.SymbolPath.CIRCLE,
                    scale: 10,
                    fillColor: marker.status === "S" ? "#f46a6a" : marker.status === "E" ? "#f46a6a" : marker.status === "N" ? "#26abfd" : "#74788d",
                    fillOpacity: 1,
                    strokeWeight: 1,
                    strokeColor: "#FFF",
                    strokeOpacity: 1,
                },
                zIndex: 998,
                label: {
                    text: marker.status === "S" ? "S" : marker.status === "E" ? "E" : "T",
                    color: "#fff",
                },
                labelStyle: { opacity: 0.75 },
                optimized: false,
            });
        });

        let geodesicPolyline = new maps.Polyline({
            path: this.state.deliverylocations,
            geodesic: true,
            strokeColor: "#0d046e",
            strokeOpacity: 0.5,
            strokeWeight: 5,
        });

        geodesicPolyline.setMap(map);
    }

    _deviceMapMarker(map, maps) {
        new maps.Marker({
            position: { lat: parseFloat(this.state.markerLoc.split(",")[0]), lng: parseFloat(this.state.markerLoc.split(",")[1]) },
            map,
            // label: {
            //     text: "",
            // },
            optimized: false,
            zIndex: 1000,
        });
    }

    componentDidMount() {
        const { locations } = this.props;

        if (this.props.deliveryid) {
            this.setState({
                ...this.state,
                locations: locations,
                defaultCenter: this.props.defaultCenter,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.deliveryid !== this.props.deliveryid) {
            const { locations } = this.props;
            this.setState(
                {
                    ...this.state,
                    locations: locations,
                    defaultCenter: this.props.defaultCenter,
                },
                () => this.onMapLoaded(this.state.map, this.state.maps)
            );
        }

        if (this.props.markerLoc && prevProps.markerLoc != this.props.markerLoc) {
            this.setState(
                {
                    ...this.state,
                    markerLoc: this.props.markerLoc,
                    defaultCenter: { lat: parseFloat(this.props.markerLoc.split(",")[0]), lng: parseFloat(this.props.markerLoc.split(",")[1]) },
                    defaultZoom: 20,
                },
                () => this._deviceMapMarker(this.state.map, this.state.maps)
            );
        }
    }

    render() {
        console.log("this.state.defaultZoom=========", this.state.defaultZoom);
        return (
            <Aux>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body" style={{ padding: "0px" }}>
                                <div className="map" style={{ height: "390px", width: "100%" }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyC3N2rnRIoLNjjKDzze8JUmLa7QJlT2YRY" }}
                                        center={this.state.defaultCenter ? this.state.defaultCenter : this.state.center}
                                        // center={this.state.center}
                                        defaultZoom={this.state.defaultZoom}
                                        onGoogleApiLoaded={({ map, maps }) => this.onMapLoaded(map, maps)}
                                        yesIWantToUseGoogleMapApiInternals
                                    >
                                        {/* {this.state.mapsLoaded ? this.afterMapLoadChanges(this.state.map, this.state.maps) : ""} */}
                                    </GoogleMapReact>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            dispatch,
        },
        dispatch
    );

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(DeliveryMap);
export { connectedPage as DeliveryMap };
// export default connect(mapStateToProps, mapDispatchToProps)(windowSize(ProductDeviceManagement));
