import { requestHeaders, requestBody, handleResponse } from "_helpers";
import { getDevicesReq, setDevicesReq } from "../_actions";

export function deleteDevice(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const requestOptions = {
            method: "DELETE",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
        };
        return fetch(`/apis/admin/devices?deviceid=${params.deviceid}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                //dispatch(deleteGatewaysReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function insertDevice(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const body = params;
        const requestOptions = {
            method: "POST",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
            body: requestBody(body),
        };
        return fetch(`/apis/admin/devices`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setDevicesReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function updateDevice(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const body = params;
        const requestOptions = {
            method: "PUT",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
            body: requestBody(body),
        };
        return fetch(`/apis/admin/devices`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(setDevicesReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getDevices(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
        };
        return fetch(`/apis/admin/devices?searchStr=${params.searchStr}&sensortype=${params.sensortype}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getDevicesReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getSensors(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
        };
        return fetch(`/apis/admin/sensors`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getDevicesReq(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}
