/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Pagination from "react-js-pagination";
import { commonUtils } from "_helpers";
import { getUsers, getProjects } from "_services";
import { User } from "_pages";
import i18n from "../../i18n";

import Aux from "../../hoc/_Aux";

class UserManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isUser: false,
            searchStr: "",
            projectid: "A",
            activePage: 1,
            pageSize: 14,
            currentPage: 1,
            users: [],
            projects: [],
            selectedUser: "",
        };
        this._handleUser = this._handleUser.bind(this);
        this._handleSaveClose = this._handleSaveClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleSelectChange = this._handleSelectChange.bind(this);
        this._getUsers = this._getUsers.bind(this);
        this._handleUserView = this._handleUserView.bind(this);
        this._getProjects = this._getProjects.bind(this);
    }
    _handleUserView(user) {
        this.setState({
            ...this.state,
            isUser: !this.state.isUser,
            selectedUser: user,
        });
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            [name]: value,
        });
    }

    _handleSelectChange(e) {
        this.setState(
            {
                ...this.state,
                projectid: e.target.value,
            },
            () => {
                this._getUsers();
            }
        );
    }

    _handleSaveClose() {
        this._getUsers();
        this._handleUser();
    }

    _handleUser() {
        this.setState({
            ...this.state,
            isUser: !this.state.isUser,
            selectedUser: "",
        });
    }
    _handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,
            currentPage: pageNumber,
        });
    }
    componentDidMount() {
        this._getUsers();
        this._getProjects();
    }

    _getUsers() {
        const { projectid, searchStr } = this.state;
        this.props.getUsers(
            {
                searchStr: searchStr,
                projectid: projectid,
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        users: result,
                    });
                }
            }
        );
    }
    _getProjects() {
        this.props.getProjects({ searchStr: "" }, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    projects: result,
                });
            }
        });
    }
    render() {
        const apipaginate = commonUtils.dataPaging(this.state.users, this.state.currentPage, this.state.pageSize);
        return (
            <Aux>
                <div className="main_content">
                    <div className="page_content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page_title_box">
                                        <h4>{i18n.t("menu_user_mng")}</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="form_box">
                                                        <div className="form-group">
                                                            <select
                                                                className="form-control"
                                                                name="projectid"
                                                                value={this.state.projectid}
                                                                onChange={this._handleSelectChange}
                                                            >
                                                                <option value="A">프로젝트 선택</option>
                                                                {this.state.projects &&
                                                                    this.state.projects.map((data) => (
                                                                        <option key={data.projectid} value={data.projectid}>
                                                                            {data.name}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                        &nbsp;
                                                        <div className="input-group" style={{ width: "300px" }}>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={i18n.t("label_usernm")}
                                                                name="searchStr"
                                                                value={this.state.searchStr}
                                                                onChange={this._handleChange}
                                                            />
                                                            <div className="input-group-append">
                                                                <button className="btn btn-secondary btn_ico" type="button" onClick={this._getUsers}>
                                                                    <i className="bx bx-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="text-sm-right">
                                                        <button type="button" className="btn btn-primary" onClick={this._handleUser}>
                                                            <i className="bx bx-plus"></i> {i18n.t("label_btn_adduser")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table table-centered table-nowrap mb-0">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">회사명</th>
                                                            <th scope="col">{i18n.t("label_userid")}</th>
                                                            <th scope="col">{i18n.t("label_usernm")}</th>
                                                            <th scope="col">{i18n.t("label_phone")}</th>
                                                            <th scope="col">{i18n.t("label_email")}</th>
                                                            <th scope="col">{i18n.t("label_created")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.users &&
                                                            apipaginate.map((data) => (
                                                                <tr className="col-12" key={data.userid}>
                                                                    <td>{data.customernm}</td>
                                                                    <td>
                                                                        <span
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => {
                                                                                this._handleUserView(data);
                                                                            }}
                                                                        >
                                                                            {data.userid}
                                                                        </span>
                                                                    </td>
                                                                    <td>{data.name}</td>
                                                                    <td>{data.phone}</td>
                                                                    <td>{data.email}</td>

                                                                    <td style={{ fontFamily: "verdana" }}>{data.createtime}</td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                                {this.state.users.length > 0 && (
                                                    <div className="data_page">
                                                        <Pagination
                                                            activePage={this.state.activePage}
                                                            itemsCountPerPage={14}
                                                            totalItemsCount={this.state.users.length}
                                                            pageRangeDisplayed={10}
                                                            onChange={this._handlePageChange.bind(this)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.isUser && (
                    <User
                        open={this.state.isUser}
                        onClose={this._handleUser}
                        onSave={this._handleSaveClose}
                        projects={this.state.projects}
                        user={this.state.selectedUser}
                        projectid={this.state.projectid}
                    />
                )}
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getUsers,
            getProjects,
            dispatch,
        },
        dispatch
    );

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(UserManagement);
export { connectedPage as UserManagement };
// export default connect(mapStateToProps, mapDispatchToProps)(windowSize(UserManagement));
