import { requestHeaders, requestBody, handleResponse } from "_helpers";
import { getProductAction, setProductAction } from "../_actions";

export function addDelivery(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const body = params;
        const requestOptions = {
            method: "POST",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
            body: requestBody(body),
        };
        return fetch(`/apis/admin/deliveries`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getProductAction(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getDeviceStatusLogs(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
        };
        return fetch(`/apis/admin/deliveries/${params.deliveryid}/devices/logs`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getProductAction(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getDeliveryLocations(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
        };
        return fetch(`/apis/admin/deliveries/${params.deliveryid}/locations`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getProductAction(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getDeliveryDeviceLogDatas(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
        };
        return fetch(`/apis/admin/deliveries/${params.deliveryid}/devices/${params.devicename}/logs`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getProductAction(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getDeviceLogsByMinute(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
        };
        return fetch(`/apis/admin/deliveries/${params.deliveryid}/devices/${params.devicename}/logs/${params.minuteUnit}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getProductAction(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function endDelivery(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const requestOptions = {
            method: "PUT",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
        };
        return fetch(`/apis/admin/deliveries/${params.deliveryid}`, requestOptions)
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getProductAction(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getDeliveriesCurrentDevices(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
        };
        return fetch(
            `/apis/admin/deliveries/devices/current?projectid=${params.projectid}&productid=${params.productid}&deliverystatus=${params.deliverystatus}`,
            requestOptions
        )
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getProductAction(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}

export function getDashboard(params = {}, callback = (error, result) => {}) {
    return (dispatch) => {
        const requestOptions = {
            method: "GET",
            headers: requestHeaders({
                "Content-Type": "application/json",
            }),
        };
        return fetch(
            `/apis/admin/dashboard?projectid=${params.projectid}&productid=${params.productid}&deliverystatus=${params.deliverystatus}&devicegbn=${params.deviceGbn}`,
            requestOptions
        )
            .then(handleResponse)
            .then((response) => {
                callback(null, response.result);
                dispatch(getProductAction(response.result));
            })
            .catch((error) => {
                callback(error);
            });
    };
}
