/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import $ from "jquery";
export class AppMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedMenu: this.props.menus[0] ? this.props.menus[0] : [],
        };
        this._handleClickMenu = this._handleClickMenu.bind(this);
    }
    _handleClickMenu(e, menu) {
        e.preventDefault();
        if (menu === "N") {
            //$("#administrator").removeClass("selected");
        } else {
            this.setState({
                ...this.state,
                selectedMenu: menu,
            });

            this.props.history.push({
                pathname: menu.path,
                state: { menuId: menu.id, menu: menu },
            });

            if (menu.child === "Y") {
                $("#administrator").addClass("selected");
            } else {
                $("#administrator").removeClass("selected");
            }
        }
    }

    render() {
        const { menus } = this.props;
        return (
            <div className="vertical_menu">
                <div className="wrapper">
                    <div className="mask">
                        <div className="offset">
                            <div className="sidebar_content_wrapper">
                                <div className="sidebar_content">
                                    <div className="sidebar_menu">
                                        <ul className="metismenu">
                                            {menus.map((menu) => {
                                                return menu.show ? (
                                                    <li key={menu.id} className={menu.class + (this.state.selectedMenu.id === menu.id ? " selected" : "")}>
                                                        <a href="#" title={menu.label} onClick={(e) => this._handleClickMenu(e, menu)}>
                                                            <i className={menu.icon}></i>
                                                            <span>{menu.title}</span>
                                                        </a>
                                                    </li>
                                                ) : null;
                                            })}
                                            <li style={{ height: "50px" }}></li>

                                            <div
                                                className="setting"
                                                id="administrator"
                                                style={{ cursor: "default" }}
                                                key="administrator"
                                                onClick={(e) => this._handleClickMenu(e, "N")}
                                            >
                                                <p style={{ cursor: "default" }}>
                                                    <i className="bx bx-cog"></i>
                                                    <span>Administrator</span>
                                                </p>
                                            </div>

                                            {this.props.supermenus.map((menu) => {
                                                return menu.show ? (
                                                    <li key={menu.id} className={menu.class + (this.state.selectedMenu.id === menu.id ? " selected" : "")}>
                                                        <a
                                                            href="#"
                                                            title={menu.label}
                                                            onClick={(e) => this._handleClickMenu(e, menu)}
                                                            // className={menu.child === "Y" ? "menu-blank" : ""}
                                                        >
                                                            <i className={menu.icon}></i>
                                                            <span>{menu.title}</span>
                                                        </a>
                                                    </li>
                                                ) : null;
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
