/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { insertProject, updateProject, deleteProject } from "_services";
import { openAlertDialog } from "../../_actions";
import { Modal } from "react-bootstrap";
import i18n from "../../i18n";
class Project extends Component {
    constructor(props) {
        super(props);

        this.state = {
            projectid: "",
            name: "",
            chargername: "",
            chargerphone: "",
            chargeremail: "",
            chargerposition: "",
            address: "",
        };

        this._handleOnClose = this._handleOnClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleRequest = this._handleRequest.bind(this);
        this._handleDeleteRequest = this._handleDeleteRequest.bind(this);
    }

    _handleRequest(e) {
        e.preventDefault();
        const { name, chargername, chargerphone, chargeremail, address, chargerposition } = this.state;
        if (!name) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_customernm"), false, "check", false, null, null, null, null));
            return;
        }
        if (!chargername) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_charger"), false, "check", false, null, null, null, null));
            return;
        }

        const project = {
            projectid: this.state.projectid,
            name: name,
            chargername: chargername,
            chargerphone: chargerphone,
            chargeremail: chargeremail,
            chargerposition: chargerposition,
            address: address,
        };

        if (this.state.projectid) {
            this.props.updateProject(project, (error, result) => {
                if (result) {
                    this._handleClose();
                }
            });
        } else {
            this.props.insertProject(project, (error, result) => {
                if (result) {
                    this._handleClose();
                }
            });
        }
    }

    _handleDeleteRequest(e) {
        e.preventDefault();
        if (this.state.projectid) {
            this.props.deleteProject({ projectid: this.state.projectid }, (error, result) => {
                if (result) {
                    this._handleClose();
                }
            });
        } else {
            this._handleClose();
        }
    }

    _handleClose() {
        this.props.onSave();
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    }

    componentDidMount() {
        const { project } = this.props;
        if (project) {
            this.setState({
                ...this.state,
                projectid: project.projectid,
                name: project.name,
                chargername: project.chargername,
                chargerphone: project.chargerphone,
                chargeremail: project.chargeremail,
                chargerposition: project.chargerposition,
                address: project.address,
            });
        }
    }

    _handleOnClose() {
        this.props.onClose();
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={() => {
                        this._handleOnClose();
                    }}
                    dialogClassName="modal-dialog modal-dialog-centered sz_xlg"
                    backdrop="static"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">프로젝트</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <form className="form-horizontal">
                                <div className="form-group">
                                    <label className="required">프로젝트 명</label>
                                    <input className="form-control " type="text" value={this.state.name} onChange={this._handleChange} name="name" />
                                </div>
                                <div className="form-group">
                                    <label className="required">{i18n.t("label_customer_charger")}</label>
                                    <input
                                        className="form-control "
                                        type="text"
                                        value={this.state.chargername}
                                        onChange={this._handleChange}
                                        name="chargername"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{i18n.t("label_customer_position")}</label>
                                    <input
                                        className="form-control "
                                        type="text"
                                        value={this.state.chargerposition}
                                        onChange={this._handleChange}
                                        name="chargerposition"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{i18n.t("label_phone")}</label>
                                    <input
                                        className="form-control "
                                        type="text"
                                        value={this.state.chargerphone}
                                        onChange={this._handleChange}
                                        name="chargerphone"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>{i18n.t("label_email")}</label>
                                    <input
                                        className="form-control "
                                        type="text"
                                        value={this.state.chargeremail}
                                        onChange={this._handleChange}
                                        name="chargeremail"
                                    />
                                </div>
                                {/* <div className="form-group">
                                    <label>{i18n.t("label_address")}</label>
                                    <input className="form-control " type="text" value={this.state.address} onChange={this._handleChange} name="address" />
                                </div> */}
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={(e) => {
                                    this._handleRequest(e);
                                }}
                            >
                                {i18n.t("label_btn_save")}
                            </button>
                            {this.state.projectid && (
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    data-dismiss="modal"
                                    onClick={(e) => {
                                        this._handleDeleteRequest(e);
                                    }}
                                >
                                    {i18n.t("label_btn_delete")}
                                </button>
                            )}
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                {i18n.t("label_btn_cancel")}
                            </button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            insertProject,
            updateProject,
            deleteProject,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Project);
export { connectedPage as Project };
