import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";
import en from "./_locales/en.json";
import ko from "./_locales/ko.json";

const resources = {
    en: {
        translation: en,
    },
    ko: {
        translation: ko,
    },
};
i18n.use(reactI18nextModule).init({
    resources,
    //lng: "en",
    lng: navigator.language || navigator.userLanguage,
    fallbackLng: "en",
    keySeparator: false,

    interpolation: {
        escapeValue: false,
    },

    wait: true,
});

export default i18n;
