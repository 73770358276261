/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Pagination from "react-js-pagination";
import { commonUtils } from "_helpers";
import { getDevices, getSensors } from "_services";
import { Device } from "./Device";
import Aux from "../../hoc/_Aux";
import i18n from "../../i18n";
class DeviceManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDevice: false,
            sensortype: "A",
            searchStr: "",
            activePage: 1,
            pageSize: 14,
            currentPage: 1,
            devices: [],
            sensors: [],
            selectedDevice: "",
        };
        this._handleDevice = this._handleDevice.bind(this);
        this._handleSaveClose = this._handleSaveClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleSelectChange = this._handleSelectChange.bind(this);
        this._handleDeviceView = this._handleDeviceView.bind(this);
        this._getDevices = this._getDevices.bind(this);
        this._getSensors = this._getSensors.bind(this);
    }

    _handleDeviceView(device) {
        this.setState({
            ...this.state,
            isDevice: !this.state.isDevice,
            selectedDevice: device,
        });
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    }

    _handleSelectChange(e) {
        this.setState(
            {
                ...this.state,
                sensortype: e.target.value,
            },
            () => {
                this._getDevices();
            }
        );
    }

    _handleSaveClose() {
        this._getDevices();
        this._handleDevice();
    }

    _handleDevice() {
        this.setState({
            ...this.state,
            isDevice: !this.state.isDevice,
            selectedDevice: "",
        });
    }
    _handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,
            currentPage: pageNumber,
        });
    }
    componentDidMount() {
        this._getDevices();
        this._getSensors();
    }

    _getDevices() {
        const { sensortype, searchStr } = this.state;
        this.props.getDevices(
            {
                sensortype: sensortype,
                searchStr: searchStr,
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        devices: result,
                    });
                }
            }
        );
    }

    _getSensors() {
        this.props.getSensors({}, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    sensors: result,
                });
            }
        });
    }
    render() {
        const apipaginate = commonUtils.dataPaging(this.state.devices, this.state.currentPage, this.state.pageSize);
        return (
            <Aux>
                <div className="main_content">
                    <div className="page_content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page_title_box">
                                        <h4>{i18n.t("menu_devices_mng")}</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="form_box">
                                                        <div className="form-group">
                                                            <select
                                                                className="form-control "
                                                                name="sensortype"
                                                                value={this.state.sensortype}
                                                                onChange={this._handleSelectChange}
                                                            >
                                                                <option value="A">{i18n.t("label_sensor_type")}</option>
                                                                {this.state.sensors &&
                                                                    this.state.sensors.map((data) => (
                                                                        <option key={data.sensorid} value={data.name}>
                                                                            {data.name}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                        &nbsp;
                                                        <div className="input-group" style={{ width: "300px" }}>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder={i18n.t("label_device_nm")}
                                                                name="searchStr"
                                                                value={this.state.searchStr}
                                                                onChange={this._handleChange}
                                                            />
                                                            <div className="input-group-append">
                                                                <button type="button" className="btn btn-secondary btn_ico" onClick={this._getDevices}>
                                                                    <i className="bx bx-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="text-sm-right">
                                                        <button type="button" className="btn btn-primary" onClick={this._handleDevice}>
                                                            <i className="bx bx-plus"></i> {i18n.t("label_btn_adddevices")}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table table-centered table-nowrap">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th>모델명</th>
                                                            <th>장치명</th>
                                                            <th>다비이스 ID</th>
                                                            <th>{i18n.t("label_device_temperature")}(min/max)</th>
                                                            <th>{i18n.t("label_device_humididy")}(min/max)</th>
                                                            <th>{i18n.t("label_device_lux")}(max)</th>
                                                            <th>{i18n.t("label_device_acceleration")}(max)</th>
                                                            <th>{i18n.t("label_device_measure_term")}</th>

                                                            <th>{i18n.t("label_created")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.devices &&
                                                            apipaginate.map((data) => (
                                                                <tr className="col-12" key={data.deviceid}>
                                                                    <td>{data.sensortype}</td>
                                                                    <td>
                                                                        <span
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => {
                                                                                this._handleDeviceView(data);
                                                                            }}
                                                                        >
                                                                            {data.name}
                                                                        </span>
                                                                    </td>
                                                                    <td>{data.uuid}</td>
                                                                    <td>
                                                                        {data.mintemperature}/{data.maxtemperature}
                                                                    </td>
                                                                    <td>
                                                                        {data.minhumidity}/{data.maxhumidity}
                                                                    </td>
                                                                    <td>{data.maxluxor}</td>
                                                                    <td>{data.maxacceleration}</td>
                                                                    <td>{data.measurementterm}</td>
                                                                    <td>{data.createtime}</td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                                {this.state.devices.length > 0 && (
                                                    <div className="text-center data_page">
                                                        <Pagination
                                                            activePage={this.state.activePage}
                                                            itemsCountPerPage={14}
                                                            totalItemsCount={this.state.devices.length}
                                                            pageRangeDisplayed={10}
                                                            onChange={this._handlePageChange.bind(this)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.isDevice && (
                    <Device
                        open={this.state.isDevice}
                        onClose={this._handleDevice}
                        onSave={this._handleSaveClose}
                        device={this.state.selectedDevice}
                        sensors={this.state.sensors}
                        sensortype={this.state.sensortype}
                    />
                )}
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getSensors,
            getDevices,
            dispatch,
        },
        dispatch
    );

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(DeviceManagement);
export { connectedPage as DeviceManagement };
