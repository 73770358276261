// import Spinner from "react-spinner-material";
import React, { Component } from "react";
import { connect } from "react-redux";

class Loading extends Component {
    constructor(props) {
        super(props);

        window.fetch.dispatch = this.props.dispatch;
    }
    render() {
        const style = {
            position: "absolute",
            left: "50%",
            top: "50%",
            zIndex: 1,
            margin: "-44px 0 0 -44px",
        };

        return this.props.loading ? (
            <div style={style}>{/* <Spinner id="loader" size={80} spinnerColor={"#5389bc"} spinnerWidth={8} visible={true} /> */}</div>
        ) : null;
    }
}

function mapStateToProps(state) {
    return {
        loading: state.loading.isShow,
    };
}
const connected = connect(mapStateToProps)(Loading);
export { connected as Loading };
