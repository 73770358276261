import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { closeAlertDialog } from "_actions";

class Alert extends Component {
    onClose = () => {
        this.props.dispatch(closeAlertDialog());
    };

    render() {
        if (!this.props.open) return null;
        return (
            <Modal
                show={this.props.open}
                onHide={() => {
                    this.onClose();
                    this.props.handleNo && this.props.handleNo();
                }}
                dialogClassName="modal-dialog-centered msgbox"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title ? this.props.title : "확인"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body">
                        <div style={{ fontSize: "14px", fontFamily: "맑은 고딕", fontWeight: "Bold" }}>{this.props.message}</div>

                        {/* <div className={"msg_content" + (this.props.icon === undefined ? "" : " " + this.props.icon)}>
                            <pre>{this.props.message}</pre>
                        </div> */}
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    {this.props.confirm && (
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                                this.onClose();
                                this.props.handleNo && this.props.handleNo();
                            }}
                        >
                            {this.props.labelNo ? this.props.labelNo : "취소"}
                        </button>
                    )}
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            this.onClose();
                            this.props.handleOk && this.props.handleOk();
                        }}
                    >
                        {this.props.labelOk ? this.props.labelOk : "확인"}
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}
function mapStateToProps(state) {
    const { open, message, title, icon, confirm, labelOk, labelNo, handleOk, handleNo } = state.alert;
    return {
        open,
        message,
        title,
        icon,
        confirm,
        labelOk,
        labelNo,
        handleOk,
        handleNo,
    };
}
const connected = connect(mapStateToProps)(Alert);
export { connected as Alert };
