/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getDeliveryDeviceLogDatas } from "_services";
import ReactEcharts from "echarts-for-react";
import { Scrollbar } from "react-scrollbars-custom";
import echarts from "echarts";
import { Modal } from "react-bootstrap";
import i18n from "../../i18n";

class ShockLog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            logs: [],
        };
    }

    componentDidMount() {}

    render() {
        return (
            <>
                <Scrollbar style={{ height: 430 }}>
                    <table className="table table-centered table-nowrap mb-0">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col"></th>
                                <th scope="col">값(G)</th>
                                <th scope="col">증감</th>
                                <th scope="col">배터리(V)</th>
                                <th scope="col">측정시간</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.logs.map((data, index) => (
                                <tr key={index} style={{ color: "Black" }}>
                                    <td>
                                        <div className="table-list-media icon">
                                            {data.statustype === "1" && <i className="fas fa-check bg-green text-white"></i>}
                                            {data.statustype === "0" && <i className="fas fa-exclamation bg-danger text-white"></i>}
                                        </div>
                                    </td>
                                    <td>
                                        <b className="mb-3">{data.sgap}</b>
                                    </td>
                                    <td>
                                        {(data.sgap > 0 || data.sgap < 0) && (
                                            <div className={"f-s-18 " + (data.sgap > 0 ? "text-red" : "text-blue")}>
                                                <i className={"fas " + (data.sgap > 0 ? "fa-caret-up" : "fa-caret-down")} style={{ fontSize: "16pt" }}></i>
                                            </div>
                                        )}
                                    </td>
                                    <td style={{ fontFamily: "verdana" }}>{data.battery}%</td>
                                    <td style={{ fontFamily: "verdana" }}>
                                        <span className="mr-10">{data.measuringdate}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Scrollbar>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getDeliveryDeviceLogDatas,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(ShockLog);
export { connectedPage as ShockLog };
