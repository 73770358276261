/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getProducts } from "_services";
import { Modal } from "react-bootstrap";
import { commonUtils } from "_helpers";

class Products extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userDevices: "",
            userid: "",
        };

        this._handleOnClose = this._handleOnClose.bind(this);
    }

    componentDidMount() {
        this.props.getProducts(
            {
                searchStr: "",
                projectid: this.props.projectid,
                categoryid: "A",
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        products: result,
                    });
                }
            }
        );
    }

    _handleOnClose() {
        this.props.onClose();
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={() => {
                        this._handleOnClose();
                    }}
                    dialogClassName="modal-dialog modal-dialog-centered modal-xlg"
                    backdrop="static"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">상품</h5>

                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="table-responsive maxheight270">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead className="thead-light"></thead>
                                    <tbody>
                                        {this.state.products &&
                                            this.state.products.map((data, index) => (
                                                <tr className="col-12" key={data.productid}>
                                                    <td className="">{index + 1}</td>
                                                    <td className="">{data.name}</td>
                                                    <td className="">{this.props.chargername}</td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getProducts,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Products);
export { connectedPage as Products };
