import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
// import ScrollMemory from "react-router-scroll-memory";
import { withCookies } from "react-cookie";
import { history } from "_helpers";
import { checkLogin } from "_services";
import { AppLayout, Loading, Alert } from "_components";
import { Login } from "_pages";

class App extends Component {
    render() {
        return (
            <ConnectedRouter history={history}>
                <Loading />
                {/* <ScrollMemory /> */}
                <Alert />
                <Switch>
                    <Route exact path="/login" name="Login" render={(props) => <Login {...props} cookies={this.props.cookies} />} />
                    <Route
                        path="/"
                        name="Home"
                        render={(props) => (checkLogin() ? <AppLayout {...props} /> : <Redirect to={{ pathname: "/login" }} {...props} />)}
                    />
                </Switch>
            </ConnectedRouter>
        );
    }
}

export default withCookies(App);
