/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
class DeviceInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            startDate: "",
            ingDate: "",
            endDate: "",
        };
    }

    componentDidMount() {
        const { locations } = this.props;
        this.setState({
            startDate: locations.find((x) => x.status === "S"),
            ingDate: locations.reverse().find((x) => x.status === "M"),
            endDate: locations.find((x) => x.status === "E"),
        });
    }

    render() {
        const { deliveryInfo, deviceInfo } = this.props;
        return (
            <>
                <div className="col-md-3">
                    <div className="card card-box-shadow">
                        <div className="card-body" style={{ padding: "18px" }}>
                            <div className="mb-2">
                                <b className="mb-2">주요 정보</b>
                            </div>

                            <div className="d-flex mb-2">
                                <div className="d-flex align-items-center">
                                    <i className="fa fa-circle" style={{ padding: "0px 5px 0px 0px", fontSize: "10px" }}></i>
                                    프로젝트
                                </div>
                                <div className="d-flex align-items-center ms-auto">
                                    <div className="w-50px text-blue font-title">{deliveryInfo.projectname}</div>
                                </div>
                            </div>

                            <div className="d-flex mb-2">
                                <div className="d-flex align-items-center">
                                    <i className="fa fa-circle " style={{ padding: "0px 5px 0px 0px", fontSize: "10px" }}></i>
                                    상품
                                </div>
                                <div className="d-flex align-items-center ms-auto">
                                    <div className="w-50px text-blue font-title">{deliveryInfo.productname}</div>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="d-flex align-items-center">
                                    <i className="fa fa-circle" style={{ padding: "0px 5px 0px 0px", fontSize: "10px" }}></i>
                                    담당자
                                </div>
                                <div className="d-flex align-items-center ms-auto">
                                    <div className="w-50px text-blue font-title">{deliveryInfo.chargername}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card card-box-shadow">
                        <div className="card-body" style={{ padding: "18px" }}>
                            <div className="mb-2">
                                <b className="mb-2">디바이스 정보</b>
                            </div>

                            <div className="d-flex mb-2">
                                <div className="d-flex align-items-center">
                                    <i className="fa fa-circle " style={{ padding: "0px 5px 0px 0px", fontSize: "10px" }}></i>
                                    장치명
                                </div>
                                <div className="d-flex align-items-center ms-auto">
                                    <div className="w-50px text-blue font-title">{deviceInfo.devicename}</div>
                                </div>
                            </div>

                            <div className="d-flex mb-2">
                                <div className="d-flex align-items-center">
                                    <i className="fa fa-circle " style={{ padding: "0px 5px 0px 0px", fontSize: "10px" }}></i>
                                    배터리 잔량
                                </div>
                                <div className="d-flex align-items-center ms-auto">
                                    <div className="w-50px text-blue font-title">{deviceInfo.battery}V</div>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="d-flex align-items-center">
                                    <i className="fa fa-circle " style={{ padding: "0px 5px 0px 0px", fontSize: "10px" }}></i>
                                    측정주기
                                </div>
                                <div className="d-flex align-items-center ms-auto">
                                    <div className="w-50px text-blue font-title">{deviceInfo.measurementterm}분</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-3">
                    <div className="card card-box-shadow">
                        <div className="card-body" style={{ padding: "18px" }}>
                            <div className="mb-2">
                                <b className="mb-2">저장 데이터</b>
                            </div>

                            <div className="d-flex mb-2">
                                <div className="d-flex align-items-center">
                                    <i className="fa fa-circle " style={{ padding: "0px 5px 0px 0px", fontSize: "10px" }}></i>
                                    로깅 시작
                                </div>
                                <div className="d-flex align-items-center ms-auto">
                                    <div className="w-50px text-blue font-title">{deviceInfo.createdate}</div>
                                </div>
                            </div>

                            <div className="d-flex mb-2">
                                <div className="d-flex align-items-center">
                                    <i className="fa fa-circle " style={{ padding: "0px 5px 0px 0px", fontSize: "10px" }}></i>
                                    마지막 로깅
                                </div>
                                <div className="d-flex align-items-center ms-auto">
                                    <div className="w-50px text-blue font-title">{deviceInfo.recentdate}</div>
                                </div>
                            </div>

                            <div className="d-flex">
                                <div className="d-flex align-items-center">
                                    <i className="fa fa-circle" style={{ padding: "0px 5px 0px 0px", fontSize: "10px" }}></i>
                                    <span className="w-50px font-title">전체 {this.props.totalcnt}</span>
                                </div>

                                <div className="d-flex align-items-center ms-auto">
                                    <i className="fa fa-circle text-success" style={{ padding: "0px 5px 0px 0px", fontSize: "10px" }}></i>
                                    <span className="w-50px font-title">정상 {this.props.totalcnt - this.props.abnormalcnt}</span>
                                </div>

                                <div className="d-flex align-items-center ms-auto">
                                    <i className="fa fa-circle text-danger" style={{ padding: "0px 5px 0px 0px", fontSize: "10px" }}></i>
                                    <span className="w-50px font-title">이상 {this.props.abnormalcnt}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card card-box-shadow">
                        <div className="card-body" style={{ padding: "18px" }}>
                            <div className="mb-2">
                                <b className="mb-2">위치 이력</b>
                            </div>

                            <div className="d-flex mb-2">
                                <div className="d-flex align-items-center">
                                    <i className="fa fa-circle " style={{ padding: "0px 5px 0px 0px", fontSize: "10px" }}></i>
                                    출발
                                </div>
                                <div className="d-flex align-items-center ms-auto">
                                    <div className="w-50px text-blue font-title">{this.state.startDate && this.state.startDate.createtime}</div>
                                </div>
                            </div>

                            <div className="d-flex mb-2">
                                <div className="d-flex align-items-center">
                                    <i className="fa fa-circle " style={{ padding: "0px 5px 0px 0px", fontSize: "10px" }}></i>
                                    최근
                                </div>
                                <div className="d-flex align-items-center ms-auto">
                                    <div className="w-50px text-blue font-title">{this.state.ingDate && this.state.ingDate.createtime}</div>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="d-flex align-items-center">
                                    <i className="fa fa-circle " style={{ padding: "0px 5px 0px 0px", fontSize: "10px" }}></i>
                                    종료
                                </div>
                                <div className="d-flex align-items-center ms-auto">
                                    <div className="w-50px text-blue font-title">{this.state.endDate && this.state.endDate.createtime}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(DeviceInfo);
export { connectedPage as DeviceInfo };
