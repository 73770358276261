import {
    COLLAPSE_MENU,
    COLLAPSE_TOGGLE,
    FULL_SCREEN,
    FULL_SCREEN_EXIT,
    CHANGE_LAYOUT,
    NAV_CONTENT_LEAVE,
    NAV_COLLAPSE_LEAVE,
    GET_DEVICES,
    SET_DEVICES,
    GET_USERS,
    SET_USERS,
    USER_LOGIN,
    USER_LOGOUT,
    GET_COMMON_CODE,
    SET_COMMON_CODE,
    GET_CUSTOMER,
    SET_CUSTOMER,
    GET_PRODUCT,
    SET_PRODUCT,
} from "../_actions";

const initialState = {};

export function defaultReducer(state = initialState, action) {
    let trigger = [];
    let open = [];

    switch (action.type) {
        case GET_PRODUCT:
            return {
                ...state,
                pending: false,
            };
        case SET_PRODUCT:
            return {
                ...state,
                pending: false,
            };
        case GET_CUSTOMER:
            return {
                ...state,
                pending: false,
            };
        case SET_CUSTOMER:
            return {
                ...state,
                pending: false,
            };
        case SET_COMMON_CODE:
            return {
                ...state,
                pending: false,
            };
        case GET_COMMON_CODE:
            return {
                ...state,
                pending: false,
            };

        case USER_LOGIN:
            return {
                ...state,
                pending: false,
            };
        case USER_LOGOUT:
            return {
                ...state,
                pending: false,
            };

        case GET_USERS:
            return {
                ...state,
                pending: false,
            };
        case SET_USERS:
            return {
                ...state,
                pending: false,
            };
        case SET_DEVICES:
            return {
                ...state,
                pending: false,
            };
        case GET_DEVICES:
            return {
                ...state,
                pending: false,
            };
        case COLLAPSE_MENU:
            return {
                ...state,
                collapseMenu: !state.collapseMenu,
            };
        case COLLAPSE_TOGGLE:
            if (action.menu.type === "sub") {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter((item) => item !== action.menu.id);
                    trigger = trigger.filter((item) => item !== action.menu.id);
                }

                if (triggerIndex === -1) {
                    open = [...open, action.menu.id];
                    trigger = [...trigger, action.menu.id];
                }
            } else {
                open = state.isOpen;
                const triggerIndex = state.isTrigger.indexOf(action.menu.id);
                trigger = triggerIndex === -1 ? [action.menu.id] : [];
                open = triggerIndex === -1 ? [action.menu.id] : [];
            }

            return {
                ...state,
                isOpen: open,
                isTrigger: trigger,
            };
        case NAV_CONTENT_LEAVE:
            return {
                ...state,
                isOpen: open,
                isTrigger: trigger,
            };
        case NAV_COLLAPSE_LEAVE:
            if (action.menu.type === "sub") {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter((item) => item !== action.menu.id);
                    trigger = trigger.filter((item) => item !== action.menu.id);
                }
                return {
                    ...state,
                    isOpen: open,
                    isTrigger: trigger,
                };
            }
            return { ...state };
        case FULL_SCREEN:
            return {
                ...state,
                isFullScreen: !state.isFullScreen,
            };
        case FULL_SCREEN_EXIT:
            return {
                ...state,
                isFullScreen: false,
            };
        case CHANGE_LAYOUT:
            return {
                ...state,
                layout: action.layout,
            };
        default:
            return state;
    }
}
