/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { insertUser, updateUser, deleteUser } from "_services";
import { openAlertDialog } from "../../_actions";
import { Modal } from "react-bootstrap";
import i18n from "../../i18n";
class User extends Component {
    constructor(props) {
        super(props);

        this.state = {
            projectid: "A",
            userid: "",
            name: "",
            password: "",
            phone: "",
            email: "",
            message: "",
        };

        this._handleOnClose = this._handleOnClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleRequest = this._handleRequest.bind(this);
        this._handleDeleteRequest = this._handleDeleteRequest.bind(this);
    }

    _handleRequest(e) {
        e.preventDefault();
        const { projectid, userid, name, phone, email, password } = this.state;
        if (!projectid) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_projectid"), false, "check", false, null, null, null, null));
            return;
        }
        if (!userid) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_userid"), false, "check", false, null, null, null, null));
            return;
        }
        if (!name) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_usernm"), false, "check", false, null, null, null, null));
            return;
        }
        const user = {
            projectid: projectid,
            userid: userid,
            name: name,
            phone: phone,
            email: email,
            password: password,
        };

        if (this.props.user) {
            this.props.updateUser(user, (error, result) => {
                if (result) {
                    this._handleClose();
                }
            });
        } else {
            this.props.insertUser(user, (error, result) => {
                if (result) {
                    if (result === 2) {
                        this.setState({
                            ...this.state,
                            message: i18n.t("msg_alert_sameid"),
                        });
                    } else {
                        this._handleClose();
                    }
                }
            });
        }
    }

    _handleDeleteRequest(e) {
        e.preventDefault();
        if (this.state.projectid) {
            this.props.deleteUser({ userid: this.state.userid }, (error, result) => {
                if (result) {
                    this._handleClose();
                }
            });
        } else {
            this._handleClose();
        }
    }

    _handleClose() {
        this.props.onSave();
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    }

    componentDidMount() {
        const { user } = this.props;
        if (user) {
            this.setState({
                ...this.state,
                projectid: user.projectid,
                userid: user.userid,
                name: user.name,
                phone: user.phone,
                email: user.email,
                password: user.password,
            });
        } else {
            this.setState({
                ...this.state,
                projectid: this.props.projectid,
            });
        }
    }

    _handleOnClose() {
        this.props.onClose();
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={() => {
                        this._handleOnClose();
                    }}
                    dialogClassName="modal-dialog modal-dialog-centered sz_xlg"
                    backdrop="static"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{i18n.t("label_user")}</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <form className="form-horizontal">
                                <div className="form-group">
                                    <label className="required">프로젝트</label>
                                    <select className="form-control" name="projectid" value={this.state.projectid} onChange={this._handleChange}>
                                        <option value="">프로젝트 선택</option>
                                        {this.props.projects &&
                                            this.props.projects.map((data) => (
                                                <option key={data.projectid} value={data.projectid}>
                                                    {data.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label className="required">
                                        {i18n.t("label_userid")} &nbsp;&nbsp;{" "}
                                        <span style={{ color: "red" }}>{this.state.message ? this.state.message : ""}</span>
                                    </label>
                                    <input
                                        className="form-control "
                                        type="text"
                                        disabled={this.props.user ? true : false}
                                        value={this.state.userid}
                                        onChange={this._handleChange}
                                        name="userid"
                                    />
                                </div>

                                <div className="form-group">
                                    <label className="required">{i18n.t("label_usernm")}</label>
                                    <input className="form-control " type="text" value={this.state.name} onChange={this._handleChange} name="name" />
                                </div>

                                <div className="form-group">
                                    <label className="required">{i18n.t("label_password")}</label>
                                    <input className="form-control " type="text" value={this.state.password} onChange={this._handleChange} name="password" />
                                </div>

                                <div className="form-group">
                                    <label>{i18n.t("label_phone")}</label>
                                    <input className="form-control " type="text" value={this.state.phone} onChange={this._handleChange} name="phone" />
                                </div>
                                <div className="form-group">
                                    <label>{i18n.t("label_email")}</label>
                                    <input className="form-control " type="text" value={this.state.email} onChange={this._handleChange} name="email" />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={(e) => {
                                    this._handleRequest(e);
                                }}
                            >
                                {i18n.t("label_btn_save")}
                            </button>
                            {this.props.user && (
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    data-dismiss="modal"
                                    onClick={(e) => {
                                        this._handleDeleteRequest(e);
                                    }}
                                >
                                    {i18n.t("label_btn_delete")}
                                </button>
                            )}
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                {i18n.t("label_btn_cancel")}
                            </button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            insertUser,
            updateUser,
            deleteUser,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(User);
export { connectedPage as User };
