import _ from "lodash";
import moment from "moment";
export const commonUtils = {
    isValidNumber,
    isValidEmail,
    hasData,
    dataPaging,
    statsDateRange,
    utcDate,
    utcPeriodicDate,
    splitArray,
    getByteLength,
    padEnd,
    dateDuration,
    dateFormat,
    dataReplace,
    localTimezoneTime,
    percent,
};
function percent(cnt, cnt1) {
    const data = Math.round((cnt / cnt1) * 100);
    return data ? data : "";
}
function dataReplace(data, chg, format) {
    return data ? data.replace(chg, format) : "";
}
function dateFormat(date) {
    return date ? moment(date).format("YYYY-MM-DD HH") : "";
}

function dateDuration(startDate, endDate) {
    const t1 = moment(startDate, "YYYY-MM-DD HH:mm");
    const t2 = moment(endDate, "YYYY-MM-DD HH:mm");

    const diffTime = {
        day: moment.duration(t2.diff(t1)).days(),
        hour: moment.duration(t2.diff(t1)).hours(),
    };
    return diffTime;
    //return moment.duration(t2.diff(t1)).asDays();
}

function splitArray(value) {
    var temp = new Array();
    temp = value.split(",");

    return temp;
}

function isValidNumber(value) {
    return window.$.isNumeric(value);
}

function isValidEmail(value) {
    var reg = new RegExp("^[\\w\\-]+(\\.[\\w\\-_]+)*@[\\w\\-]+(\\.[\\w\\-]+)*(\\.[a-zA-Z]{2,3})$", "gi");
    if (reg.test(value)) {
        return true;
    }
    return false;
}

function hasData(obj) {
    return obj && obj[0] ? true : false;
}

function dataPaging(items, pageNumber, pageSize) {
    const startIndex = (pageNumber - 1) * pageSize; // 자를 배열의 시작점

    return _(items)
        .slice(startIndex) // 시작점부터 배열을 자르되
        .take(pageSize) // pageSize만큼의 배열을 취함
        .value(); // lodash wrapper 객체를 regular 배열로 변환
}

function utcDate(data, flag) {
    return flag === "start"
        ? moment(data).startOf("day").utc(true).format("YYYY-MM-DD HH:mm:ss")
        : moment(data).endOf("day").utc(true).format("YYYY-MM-DD HH:mm:ss");
}

function utcPeriodicDate(data, flag) {
    return flag === "start" ? moment(data).startOf("day").utc(true).format("YYYYMMDD") : moment(data).endOf("day").utc(true).format("YYYYMMDD");
}

function statsDateRange(baseDay, interval, flag, value) {
    const letdate = moment(baseDay, "YYYY-MM-DD");
    if (interval === "daily") {
        if (flag === "prev") {
            return letdate.subtract(1, "days").format("YYYY-MM-DD");
        } else if (flag === "next") {
            return letdate.add(1, "days").format("YYYY-MM-DD");
        } else {
            return letdate.format("YYYY-MM-DD");
        }
    } else if (interval === "weekly") {
        if (flag === "prev") {
            return value === "start"
                ? letdate.subtract(1, "weeks").startOf("isoWeek").format("YYYY-MM-DD")
                : letdate.subtract(1, "weeks").endOf("isoWeek").format("YYYY-MM-DD");
        } else if (flag === "next") {
            return value === "start"
                ? letdate.add(1, "weeks").startOf("isoWeek").format("YYYY-MM-DD")
                : letdate.add(1, "weeks").endOf("isoWeek").format("YYYY-MM-DD");
        } else {
            return value === "start" ? moment().startOf("isoWeek").format("YYYY-MM-DD") : moment().endOf("isoWeek").format("YYYY-MM-DD");
        }
    } else if (interval === "monthly") {
        if (flag === "prev") {
            return value === "start"
                ? letdate.subtract(1, "months").startOf("month").format("YYYY-MM-DD")
                : letdate.subtract(1, "months").endOf("month").format("YYYY-MM-DD");
        } else if (flag === "next") {
            return value === "start"
                ? letdate.add(1, "months").startOf("month").format("YYYY-MM-DD")
                : letdate.add(1, "months").endOf("month").format("YYYY-MM-DD");
        } else {
            return value === "start" ? moment().startOf("month").format("YYYY-MM-DD") : moment().endOf("month").format("YYYY-MM-DD");
        }
    }
}

function getByteLength(str) {
    var strLength = 0;

    for (let i = 0; i < str.length; i++) {
        var code = str.charCodeAt(i);
        var ch = str.substr(i, 1).toUpperCase();
        code = parseInt(code);

        if ((ch < "0" || ch > "9") && (ch < "A" || ch > "Z") && (code > 255 || code < 0)) strLength = strLength + 2;
        else strLength = strLength + 1;
    }
    return strLength;
}

function padEnd(str, num, pad) {
    let padStr = [];
    const strLength = getByteLength(str);
    padStr.push(str);
    for (let i = 0; i < num - strLength; i++) {
        padStr.push(pad);
    }
    return padStr.join("");
}

function localTimezoneTime(data) {
    //var tzName = moment.tz.guess();
    //return moment.utc(data).tz(tzName).format("MM/DD");

    return moment(data).format("MM/DD");
}
