/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonUtils } from "_helpers";
import { getProjects, getDeliveries, getDeliveryDevices, getProducts } from "_services";
import { DeviceMatch, DeviceMatchView } from "_pages";

import Pagination from "react-js-pagination";
import i18n from "../../i18n";
import Aux from "../../hoc/_Aux";

class ProductDeviceManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDeviceMatch: false,
            isDeviceMatchView: false,
            projectid: "A",
            deliverystatus: "A",
            activePage: 1,
            pageSize: 14,
            currentPage: 1,
            deliveries: [],
            projects: [],
            products: [],
            selectedDeliveryid: "",
            deliveryDevices: "",
        };

        this._handleSaveClose = this._handleSaveClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleSelectChange = this._handleSelectChange.bind(this);
        this._getDeliveries = this._getDeliveries.bind(this);
        this._handleDeviceMatch = this._handleDeviceMatch.bind(this);
        this._handleDeviceSetting = this._handleDeviceSetting.bind(this);
        this._handleDeviceView = this._handleDeviceView.bind(this);
        this._handleDeviceMatchView = this._handleDeviceMatchView.bind(this);
        this._handleSaveViewClose = this._handleSaveViewClose.bind(this);
        this._getProducts = this._getProducts.bind(this);
    }

    _handleSaveViewClose() {
        this.setState({
            ...this.state,
            isDeviceMatchView: !this.state.isDeviceMatchView,
            selectedDeliveryid: "",
            deliveryDevices: "",
        });
    }

    _handleDeviceView(deliveryid) {
        this.props.getDeliveryDevices({ deliveryid: deliveryid }, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    isDeviceMatchView: !this.state.isDeviceMatchView,
                    selectedDeliveryid: deliveryid,
                    deliveryDevices: result,
                });
            }
        });
    }

    _handleDeviceSetting(deliveryid) {
        this.setState({
            ...this.state,
            isDeviceMatch: !this.state.isDeviceMatch,
            selectedDeliveryid: deliveryid,
            deliveryDevices: "",
        });
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            [name]: value,
        });
    }

    _handleSelectChange(e, searchFlag) {
        this.setState(
            {
                ...this.state,
                [searchFlag]: e.target.value,
            },
            () => {
                this._getDeliveries();
            }
        );
    }

    _handleSaveClose() {
        this._getDeliveries();
        this._handleDeviceMatch();
    }

    _handleDeviceMatch() {
        this.setState({
            ...this.state,
            isDeviceMatch: !this.state.isDeviceMatch,
            selectedDeliveryid: "",
        });
    }

    _handleDeviceMatchView() {
        this.setState({
            ...this.state,
            isDeviceMatchView: !this.state.isDeviceMatchView,
            selectedDeliveryid: "",
            deliveryDevices: "",
        });
    }

    _handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,
            currentPage: pageNumber,
        });
    }

    componentDidMount() {
        this._getDeliveries();
        this._getProjects();
    }

    _getDeliveries() {
        const { projectid, deliverystatus } = this.state;
        this.props.getDeliveries(
            {
                projectid: projectid,
                deliverystatus: deliverystatus,
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        deliveries: result,
                    });
                }
            }
        );
    }
    _getProjects() {
        this.props.getProjects({ searchStr: "" }, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    projects: result,
                });
            }
        });
    }

    _getProducts() {
        const { projectid } = this.state;
        this.props.getProducts(
            {
                searchStr: "",
                projectid: projectid,
                categoryid: "A",
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        products: result,
                    });
                }
            }
        );
    }
    render() {
        const apipaginate = commonUtils.dataPaging(this.state.deliveries, this.state.currentPage, this.state.pageSize);
        return (
            <Aux>
                <div className="main_content">
                    <div className="page_content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page_title_box">
                                        <h4>{i18n.t("menu_devices_product_mng")}</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="form_box">
                                                        <div className="form-group">
                                                            <select
                                                                className="form-control"
                                                                name="projectid"
                                                                value={this.state.projectid}
                                                                onChange={(e) => this._handleSelectChange(e, "projectid")}
                                                            >
                                                                <option value="A">프로젝트 선택</option>
                                                                {this.state.projects &&
                                                                    this.state.projects.map((data) => (
                                                                        <option key={data.projectid} value={data.projectid}>
                                                                            {data.name}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                        &nbsp;&nbsp;
                                                        <div className="form-group">
                                                            <select
                                                                className="form-control"
                                                                name="productid"
                                                                value={this.state.productid}
                                                                onChange={(e) => this._handleSelectChange(e, "productid")}
                                                            >
                                                                <option value="A">상품 선택</option>
                                                                {this.state.products &&
                                                                    this.state.products.map((data) => (
                                                                        <option key={data.productid} value={data.productid}>
                                                                            {data.name}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table table-centered table-nowrap mb-0">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">{i18n.t("label_customer_nm")}</th>
                                                            <th scope="col">{i18n.t("label_category")}</th>
                                                            <th scope="col">{i18n.t("label_product_nm")}</th>
                                                            <th scope="col">{i18n.t("label_quantity")}</th>
                                                            <th scope="col">{i18n.t("label_shippingaddress")}</th>
                                                            <th scope="col" className="text-center">
                                                                {i18n.t("label_status")}
                                                            </th>

                                                            <th scope="col" style={{ width: "120px" }}>
                                                                {i18n.t("label_created")}
                                                            </th>
                                                            <th scope="col" className="text-left" style={{ width: "100px" }}>
                                                                {i18n.t("label_device_connect")}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.deliveries &&
                                                            apipaginate.map((data) => (
                                                                <tr
                                                                    className="col-12"
                                                                    key={data.deliveryid}
                                                                    // style={{ backgroundColor: data.deliverystatus === "Y" ? "" : "#f5f5f5" }}
                                                                >
                                                                    <td>{data.customername}</td>
                                                                    <td>{data.categoryname}</td>
                                                                    <td>{data.productname}</td>
                                                                    <td>{data.quantity}</td>
                                                                    <td>{data.shippingaddress}</td>
                                                                    <td className="text-center">{i18n.t("label_delivery_status_" + data.status)}</td>

                                                                    <td style={{ fontFamily: "verdana" }}>{data.createtime}</td>

                                                                    <td className="text-center" style={{ width: "70px" }}>
                                                                        {data.devicematchyn === "N" ? (
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-danger btn-sm"
                                                                                style={{ fontSize: "11px" }}
                                                                                onClick={(e) => this._handleDeviceSetting(data.deliveryid)}
                                                                            >
                                                                                Setting
                                                                            </button>
                                                                        ) : (
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-primary btn-sm"
                                                                                style={{ fontSize: "11px" }}
                                                                                onClick={() => this._handleDeviceView(data.deliveryid)}
                                                                            >
                                                                                View
                                                                            </button>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                                {this.state.deliveries.length > 0 && (
                                                    <div className="data_page">
                                                        <Pagination
                                                            activePage={this.state.activePage}
                                                            itemsCountPerPage={14}
                                                            totalItemsCount={this.state.deliveries.length}
                                                            pageRangeDisplayed={10}
                                                            onChange={this._handlePageChange.bind(this)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isDeviceMatch && (
                    <DeviceMatch
                        open={this.state.isDeviceMatch}
                        onClose={this._handleDeviceMatch}
                        onSave={this._handleSaveClose}
                        deliveryid={this.state.selectedDeliveryid}
                    />
                )}
                {this.state.isDeviceMatchView && (
                    <DeviceMatchView
                        open={this.state.isDeviceMatchView}
                        onClose={this._handleDeviceMatchView}
                        onSave={this._handleSaveViewClose}
                        deliveryid={this.state.selectedDeliveryid}
                        devices={this.state.deliveryDevices}
                    />
                )}
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getDeliveries,
            getProjects,
            getDeliveryDevices,
            getProducts,
            dispatch,
        },
        dispatch
    );

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(ProductDeviceManagement);
export { connectedPage as ProductDeviceManagement };
// export default connect(mapStateToProps, mapDispatchToProps)(windowSize(ProductDeviceManagement));
