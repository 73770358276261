import { ALERT_CLOSE, ALERT_NONE, ALERT_OPEN } from "../_actions";

const initialState = {
    open: false,
};

export function alert(state = initialState, action) {
    switch (action.type) {
        case ALERT_OPEN:
            return {
                open: true,
                message: action.message,
                title: action.title,
                icon: action.icon,
                confirm: action.confirm,
                labelOk: action.labelOk,
                labelNo: action.labelNo,
                handleOk: action.handleOk,
                handleNo: action.handleNo,
            };
        case ALERT_CLOSE:
            return {
                open: false,
            };

        case ALERT_NONE:
            return state;
        default:
            return state;
    }
}
