export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const COLLAPSE_TOGGLE = "COLLAPSE_TOGGLE";
export const FULL_SCREEN = "FULL_SCREEN";
export const FULL_SCREEN_EXIT = "FULL_SCREEN_EXIT";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const NAV_CONTENT_LEAVE = "NAV_CONTENT_LEAVE";
export const NAV_COLLAPSE_LEAVE = "NAV_COLLAPSE_LEAVE";

export const GET_DEVICES = "GET_DEVICES";
export const SET_USER_DEVICE_CONNECT = "SET_USER_DEVICE_CONNECT";
export const SET_DEVICES = "GET_DEVICES";
export const SET_DELETE_DEVICES = "SET_DELETE_DEVICES";

export const GET_USERS = "GET_USERS";
export const SET_USERS = "SET_USERS";

export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";

export const GET_COMMON_CODE = "GET_COMMON_CODE";
export const SET_COMMON_CODE = "SET_COMMON_CODE";

export const GET_CUSTOMER = "GET_CUSTOMER";
export const SET_CUSTOMER = "SET_CUSTOMER";

export const GET_PRODUCT = "GET_PRODUCT";
export const SET_PRODUCT = "SET_PRODUCT";

export function getProductAction() {
    return {
        type: GET_PRODUCT,
    };
}

export function setProductAction() {
    return {
        type: SET_PRODUCT,
    };
}

export function getCustomerAction() {
    return {
        type: GET_CUSTOMER,
    };
}

export function setCustomerAction() {
    return {
        type: SET_CUSTOMER,
    };
}

export function setCommonCodeReq() {
    return {
        type: SET_COMMON_CODE,
    };
}

export function getCommonCodeReq() {
    return {
        type: GET_COMMON_CODE,
    };
}

export function deleteDevicesReq() {
    return {
        type: SET_DELETE_DEVICES,
    };
}

export function userLoginReq(error) {
    return { type: USER_LOGIN, error };
}

export function userLogout() {
    return { type: USER_LOGOUT };
}

export function gerUsersReq() {
    return {
        type: GET_USERS,
    };
}

export function setUserReq() {
    return {
        type: SET_USERS,
    };
}

export function getDevicesReq() {
    return {
        type: GET_DEVICES,
    };
}

export function setDevicesReq() {
    return {
        type: SET_DEVICES,
    };
}
