/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonUtils } from "_helpers";
import GoogleMapReact from "google-map-react";
import Aux from "../../hoc/_Aux";
import { syncTranslationWithStore } from "react-redux-i18n";

class DeliveryLocation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deliveryid: "",
            mapsLoaded: false,
            map: null,
            maps: null,
            locations: [],
            deliveryDuration: "",
            deliverylocations: [],
            center: { lat: 40.73, lng: -73.93 },
        };
    }

    onMapLoaded(map, maps) {
        this.googleMapRef = map;
        this.googleRef = maps;

        const delilocations = [];
        for (let marker of this.props.locations) {
            if (marker.latitude && marker.longitude) {
                delilocations.push({ status: marker.status, lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) });
            }
        }

        this.setState({
            ...this.state,
            mapsLoaded: true,
            map: map,
            maps: maps,
            deliverylocations: delilocations,
        });
    }

    afterMapLoadChanges(map, maps) {
        this.state.deliverylocations.map((marker, index) => {
            new maps.Marker({
                position: { lat: parseFloat(marker.lat), lng: parseFloat(marker.lng) },
                map,
                icon: {
                    path: maps.SymbolPath.CIRCLE,
                    scale: 10,
                    fillColor: marker.status === "S" ? "#f46a6a" : marker.status === "E" ? "#f46a6a" : "#74788d",
                    fillOpacity: 1,
                    strokeWeight: 2,
                    strokeColor: "#FFF",
                    strokeOpacity: 1,
                },
                zIndex: 998,
                label: {
                    text: marker.status === "S" ? "S" : marker.status === "E" ? "E" : "T",
                    color: "#fff",
                },
                labelStyle: { opacity: 0.75 },
                optimized: false,
            });
        });

        let geodesicPolyline = new maps.Polyline({
            path: this.state.deliverylocations,
            geodesic: true,
            strokeColor: "#0d046e",
            strokeOpacity: 0.5,
            strokeWeight: 7,
        });
        geodesicPolyline.setMap(map);
    }

    componentDidMount() {
        const { locations } = this.props;

        if (this.props.deliveryid) {
            this.setState({
                ...this.state,
                locations: locations,
            });
        }

        // if (this.props.deliveryid) {
        //     let dateDiff = commonUtils.dateDuration(locations[0].createtime, locations[locations.length - 1].createtime);
        //     this.setState({
        //         ...this.state,
        //         deliveryDuration: dateDiff.day + "일 " + dateDiff.hour + "시간",
        //     });
        // }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.deliveryid !== this.props.deliveryid) {
            const { locations } = this.props;

            this.onMapLoaded(this.state.map, this.state.maps);
            //this.afterMapLoadChanges(this.state.map, this.state.maps);
            this.setState({
                ...this.state,
                locations: locations,
            });
        }
    }

    render() {
        return (
            <Aux>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body" style={{ padding: "0px" }}>
                                <div className="map" style={{ height: "400px", width: "100%" }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: "AIzaSyC3N2rnRIoLNjjKDzze8JUmLa7QJlT2YRY" }}
                                        center={this.props.defaultCenter}
                                        // center={this.state.center}
                                        defaultZoom={14}
                                        onGoogleApiLoaded={({ map, maps }) => this.onMapLoaded(map, maps)}
                                        yesIWantToUseGoogleMapApiInternals
                                    >
                                        {this.state.mapsLoaded ? this.afterMapLoadChanges(this.state.map, this.state.maps) : ""}
                                    </GoogleMapReact>
                                </div>
                                {/* <div style={{ position: "relative" }}>
                        <div className="location-panel">
                            <Scrollbar style={{ height: 400 }}>
                                <table className="table table-centered table-nowrap mb-0">
                                    <tbody>
                                        <tr style={{ color: "Black", height: "28px", backgroundColor: "#dddddd" }}>
                                            <td colSpan="2" style={{ fontFamily: "verdana", padding: "10px 0px 10px 10px" }} className="text-left">
                                                <b className="">운행 시간 : {this.state.deliveryDuration}</b>
                                            </td>
                                        </tr>
                                        {this.props.locations.map((data, index) => (
                                            <tr key={index} style={{ color: "Black" }}>
                                                <td>
                                                    <b className="mb-3">
                                                        <span
                                                            className={
                                                                data.status === "E"
                                                                    ? "label label-danger"
                                                                    : data.status === "M"
                                                                    ? "label label-secondary"
                                                                    : data.status === "S"
                                                                    ? "label label-green"
                                                                    : "label label-primary"
                                                            }
                                                        >
                                                            {data.status === "0"
                                                                ? "등록"
                                                                : data.status === "S"
                                                                ? "출발"
                                                                : data.status === "M"
                                                                ? "중간"
                                                                : "도착"}
                                                          
                                                        </span>
                                                    </b>
                                                </td>
                                                <td style={{ fontFamily: "verdana" }}>{data.createtime}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Scrollbar>
                        </div>
                    </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            dispatch,
        },
        dispatch
    );

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(DeliveryLocation);
export { connectedPage as DeliveryLocation };
// export default connect(mapStateToProps, mapDispatchToProps)(windowSize(ProductDeviceManagement));
