import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { alert } from "./alert.reducer";
import { loading } from "./loading.reducer";
import { defaultReducer } from "./reducer";

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        loading,
        alert,
        defaultReducer,
    });

export default createRootReducer;
