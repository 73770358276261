/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { insertProduct, updateProduct, deleteProduct } from "_services";
import { openAlertDialog } from "../../_actions";
import { Modal } from "react-bootstrap";
import i18n from "../../i18n";
class Product extends Component {
    constructor(props) {
        super(props);

        this.state = {
            projectid: "",
            productid: "",
            manufacturenm: "",
            deliveryplace: "",
            memo: "",
            name: "",
            code: "",
        };

        this._handleOnClose = this._handleOnClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleRequest = this._handleRequest.bind(this);
        this._handleDeleteRequest = this._handleDeleteRequest.bind(this);
    }

    _handleRequest(e) {
        e.preventDefault();
        const { projectid, productid, name, code, manufacturenm, deliveryplace, memo } = this.state;
        if (projectid === "A") {
            this.props.dispatch(openAlertDialog("프로젝트를 선택하세요!", false, "check", false, null, null, null, null));
            return;
        }
        if (!name) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_productnm"), false, "check", false, null, null, null, null));
            return;
        }
        const product = {
            projectid: projectid,
            productid: productid,
            code: code,
            manufacturenm: manufacturenm,
            deliveryplace: deliveryplace,
            memo: memo,
            name: name,
        };

        if (this.props.product) {
            this.props.updateProduct(product, (error, result) => {
                if (result) {
                    this._handleClose();
                }
            });
        } else {
            this.props.insertProduct(product, (error, result) => {
                if (result) {
                    this._handleClose();
                }
            });
        }
    }

    _handleDeleteRequest(e) {
        e.preventDefault();
        if (this.state.productid) {
            this.props.deleteProduct({ productid: this.state.productid }, (error, result) => {
                if (result) {
                    this._handleClose();
                }
            });
        } else {
            this._handleClose();
        }
    }

    _handleClose() {
        this.props.onSave();
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    }

    componentDidMount() {
        const { product } = this.props;
        if (product) {
            this.setState({
                ...this.state,
                projectid: product.projectid,
                productid: product.productid,
                name: product.name,
                manufacturenm: product.manufacturenm ? product.manufacturenm : "",
                deliveryplace: product.deliveryplace ? product.deliveryplace : "",
                memo: product.memo ? product.memo : "",
                code: product.code,
            });
        } else {
            let min = 10000000000;
            let max = 99999999999;
            this.setState({
                ...this.state,
                projectid: this.props.projectid,
                code: Math.round(min + Math.random() * (max - min)),
            });
        }
    }

    _handleOnClose() {
        this.props.onClose();
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={() => {
                        this._handleOnClose();
                    }}
                    dialogClassName="modal-dialog modal-dialog-centered sz_xlg"
                    backdrop="static"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{i18n.t("label_product")}</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <form className="form-horizontal">
                                <div className="form-group">
                                    <label className="required">프로젝트</label>
                                    <select className="form-control" name="projectid" value={this.state.projectid} onChange={this._handleChange}>
                                        <option value="A">프로젝트 선택</option>
                                        {this.props.projects &&
                                            this.props.projects.map((data, index) => (
                                                <option key={index} value={data.projectid}>
                                                    {data.name}
                                                </option>
                                            ))}
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label className="required">{i18n.t("label_product_nm")}</label>
                                    <input className="form-control " type="text" value={this.state.name} onChange={this._handleChange} name="name" />
                                </div>
                                <div className="form-group">
                                    <label className="required">상품코드</label>
                                    <input className="form-control" readOnly type="text" value={this.state.code} onChange={this._handleChange} name="code" />
                                </div>
                                <div className="form-group">
                                    <label className="">제조원</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={this.state.manufacturenm}
                                        onChange={this._handleChange}
                                        name="manufacturenm"
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="">납품처</label>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={this.state.deliveryplace}
                                        onChange={this._handleChange}
                                        name="deliveryplace"
                                    />
                                </div>
                                <div className="form-group">
                                    <label className="">메모</label>
                                    <input className="form-control " type="text" value={this.state.memo} onChange={this._handleChange} name="memo" />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={(e) => {
                                    this._handleRequest(e);
                                }}
                            >
                                {i18n.t("label_btn_save")}
                            </button>
                            {this.props.product && (
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    data-dismiss="modal"
                                    onClick={(e) => {
                                        this._handleDeleteRequest(e);
                                    }}
                                >
                                    {i18n.t("label_btn_delete")}
                                </button>
                            )}
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                {i18n.t("label_btn_cancel")}
                            </button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            insertProduct,
            updateProduct,
            deleteProduct,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Product);
export { connectedPage as Product };
