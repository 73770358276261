/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { commonUtils } from "_helpers";
import { getProjects, getProducts, getDashboard } from "_services";
import { DeviceMonitoring } from "_pages";
import Pagination from "react-js-pagination";
import i18n from "../../i18n";
import Aux from "../../hoc/_Aux";

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            projectid: "A",
            productid: "A",
            activePage: 1,
            pageSize: 20,
            currentPage: 1,
            projects: [],
            products: [],
            defaultCenter: {},
            devices: [],
            isDevice: false,
            deliveryid: "",
            deliveryInfo: {},
            deliverystatus: "A",
            devicestat: "",
            deviceGbn: "A",
        };
        this._handleChange = this._handleChange.bind(this);
        this._handleSelectChange = this._handleSelectChange.bind(this);
        this._getDashobard = this._getDashobard.bind(this);
        this._getProducts = this._getProducts.bind(this);
        this._handleDevice = this._handleDevice.bind(this);
        this._handleClose = this._handleClose.bind(this);
        this._handleStatus = this._handleStatus.bind(this);
        this._handleWidget = this._handleWidget.bind(this);
        this._handleReset = this._handleReset.bind(this);
    }

    _handleReset() {
        this.setState(
            {
                projectid: "A",
                productid: "A",
                deliverystatus: "A",
                deviceGbn: "A",
            },
            () => {
                this._getDashobard();
            }
        );
    }

    _handleWidget(flag, status) {
        if (flag === "delivery") {
            this.setState(
                {
                    deliverystatus: status,
                },
                () => {
                    this._getDashobard();
                }
            );
        } else if (flag === "device") {
            this.setState(
                {
                    deviceGbn: status,
                },
                () => {
                    this._getDashobard();
                }
            );
        }
    }

    _handleDevice(deliveryid, location, locations, data) {
        const delivery = {
            projectname: data.projectname,
            productname: data.productname,
            chargername: data.chargername,
        };
        const center = location ? location.split("|") : "0|0";
        this.setState({
            isDevice: !this.state.isDevice,
            deliveryid: deliveryid,
            locations: locations,
            defaultCenter: location ? { lat: parseFloat(center[0]), lng: parseFloat(center[1]) } : { lat: 40.73, lng: -73.93 },
            deviceInfo: data,
            deliveryInfo: delivery,
        });
    }

    _handleClose() {
        this.setState({
            isDevice: !this.state.isDevice,
            deliveryid: "",
            locations: [],
            defaultCenter: {},
            deviceInfo: "",
            deliveryInfo: {},
        });
    }

    _handleStatus(e) {
        this.setState(
            {
                deliverystatus: e.target.value,
            },
            () => {
                this._getDashobard();
            }
        );
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            [name]: value,
        });
    }

    _handleSelectChange(e, searchFlag) {
        this.setState(
            {
                ...this.state,
                [searchFlag]: e.target.value,
                productid: searchFlag === "projectid" ? "A" : e.target.value,
            },
            () => {
                this._getDashobard();
                if (searchFlag === "projectid") this._getProducts();
            }
        );
    }

    _handlePageChange(pageNumber) {
        this.setState({
            activePage: pageNumber,
            currentPage: pageNumber,
        });
    }

    componentDidMount() {
        //this._getDashobard();
        this._getProjects();
    }

    _getDashobard() {
        const { projectid, productid, deliverystatus, deviceGbn } = this.state;
        this.props.getDashboard(
            {
                projectid: projectid,
                productid: productid,
                deliverystatus: deliverystatus,
                deviceGbn: deviceGbn,
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        devices: result.devices,
                        devicestat: result,
                    });
                }
            }
        );
    }

    _getProjects() {
        this.props.getProjects({ searchStr: "" }, (error, result) => {
            if (result) {
                this.setState(
                    {
                        ...this.state,
                        projects: result,
                        projectid: result[0].projectid,
                    },
                    () => {
                        this._getDashobard();
                        this._getProducts();
                    }
                );
            }
        });
    }

    _getProducts() {
        const { projectid } = this.state;
        this.props.getProducts(
            {
                searchStr: "",
                projectid: projectid,
                categoryid: "A",
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        products: result,
                    });
                }
            }
        );
    }
    render() {
        const apipaginate = commonUtils.dataPaging(this.state.devices, this.state.currentPage, this.state.pageSize);
        const { devicestat } = this.state;
        return (
            <Aux>
                <div className="main_content">
                    <div className="page_content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-8">
                                                    <div className="form_box " style={{ marginBottom: "0px" }}>
                                                        <div className="form-group">
                                                            <select
                                                                className="form-control"
                                                                name="projectid"
                                                                value={this.state.projectid}
                                                                onChange={(e) => this._handleSelectChange(e, "projectid")}
                                                            >
                                                                <option value="A">프로젝트 선택</option>
                                                                {this.state.projects &&
                                                                    this.state.projects.map((data) => (
                                                                        <option key={data.projectid} value={data.projectid}>
                                                                            {data.name}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                        &nbsp;&nbsp;
                                                        <div className="form-group">
                                                            <select
                                                                className="form-control"
                                                                name="productid"
                                                                value={this.state.productid}
                                                                onChange={(e) => this._handleSelectChange(e, "productid")}
                                                            >
                                                                <option value="A">상품 선택</option>
                                                                {this.state.products &&
                                                                    this.state.products.map((data) => (
                                                                        <option key={data.productid} value={data.productid}>
                                                                            {data.name}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        </div>
                                                        &nbsp;&nbsp;
                                                        {/* <div className="form-group">
                                                    <select
                                                        className="form-control"
                                                        name="deliverystatus"
                                                        value={this.state.deliverystatus}
                                                        onChange={(e) => this._handleStatus(e)}
                                                    >
                                                        <option value="A">배송 상태 선택</option>
                                                        <option value="M">배송 중</option>
                                                        <option value="E">배송 완료</option>
                                                    </select>
                                                </div> */}
                                                    </div>
                                                </div>

                                                <div className="col-4">
                                                    <div className="text-sm-right">
                                                        <button type="button" className="btn btn-primary" onClick={this._handleReset}>
                                                            <i className="fa fa-list"></i> 전체보기
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-3">
                                    <div
                                        className="widget widget-stats bg-blue"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => this._handleWidget("delivery", "M")}
                                    >
                                        <div className="stats-icon">
                                            <i className="fa fa-truck" style={{ color: "#007bff" }}></i>
                                        </div>
                                        <div className="stats-info">
                                            <h4 style={{ fontSize: "14px" }}>
                                                <i className="fa fa-truck" style={{ color: "#007bff" }}></i> 배송 중
                                            </h4>
                                            <p style={{ fontSize: "26px", fontFamily: "NanumSquare-Bold" }}>
                                                {devicestat.delcnt} 개 ({commonUtils.percent(devicestat.delcnt, devicestat.delallcnt)}
                                                <span style={{ fontSize: "13px" }}>%</span>)
                                            </p>
                                        </div>
                                        <div className="progress h-5px rounded-3 bg-gray-900 mb-5px">
                                            <div
                                                className="progress-bar progress-bar-blue rounded-right bg-teal"
                                                data-animation="width"
                                                style={{ width: commonUtils.percent(devicestat.delcnt, devicestat.delallcnt) + "%" }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div
                                        className="widget widget-stats bg-blue"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => this._handleWidget("device", "Y")}
                                    >
                                        <div className="stats-icon">
                                            <i className="fa fa-check-circle" style={{ color: "#3ca657" }}></i>
                                        </div>
                                        <div className="stats-info">
                                            <h4 style={{ fontSize: "14px" }}>
                                                <i className="fa fa-check-circle" style={{ color: "#3ca657" }}></i> 정상
                                            </h4>
                                            <p style={{ fontSize: "26px", fontFamily: "NanumSquare-Bold" }}>
                                                {devicestat.normalcnt} 개 ({commonUtils.percent(devicestat.normalcnt, devicestat.totalcnt)}
                                                <span style={{ fontSize: "13px" }}>%</span>)
                                            </p>
                                        </div>
                                        <div className="progress h-5px rounded-3 bg-gray-900 mb-5px">
                                            <div
                                                className="progress-bar progress-bar-green rounded-right bg-teal"
                                                data-animation="width"
                                                style={{ width: commonUtils.percent(devicestat.normalcnt, devicestat.totalcnt) + "%" }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div
                                        className="widget widget-stats bg-blue"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => this._handleWidget("device", "N")}
                                    >
                                        <div className="stats-icon">
                                            <i className="fa fa-exclamation-circle" style={{ color: "#dc3545" }}></i>
                                        </div>
                                        <div className="stats-info">
                                            <h4 style={{ fontSize: "14px" }}>
                                                <i className="fa fa-exclamation-circle" style={{ color: "#dc3545" }}></i> 이상
                                            </h4>
                                            <p style={{ fontSize: "26px", fontFamily: "NanumSquare-Bold" }}>
                                                {devicestat.abnormalcnt} 개 ({commonUtils.percent(devicestat.abnormalcnt, devicestat.totalcnt)}
                                                <span style={{ fontSize: "13px" }}>%</span>)
                                            </p>
                                        </div>
                                        <div className="progress h-5px rounded-3 bg-gray-900 mb-5px">
                                            <div
                                                className="progress-bar progress-bar-rred rounded-right bg-teal"
                                                data-animation="width"
                                                style={{ width: commonUtils.percent(devicestat.abnormalcnt, devicestat.totalcnt) + "%" }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div
                                        className="widget widget-stats bg-blue"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => this._handleWidget("device", "B")}
                                    >
                                        <div className="stats-icon">
                                            <i className="fa fa-battery-quarter" style={{ color: "#c92424" }}></i>
                                        </div>
                                        <div className="stats-info">
                                            <h4 style={{ fontSize: "14px" }}>
                                                <i className="fa fa-battery-quarter" style={{ color: "#c92424" }}></i> 배터리 부족
                                            </h4>
                                            <p style={{ fontSize: "26px", fontFamily: "NanumSquare-Bold" }}>
                                                {devicestat.lowbattery} 개 ({commonUtils.percent(devicestat.lowbattery, devicestat.totalcnt)}
                                                <span style={{ fontSize: "13px" }}>%</span>)
                                            </p>
                                        </div>
                                        <div className="progress h-5px rounded-3 bg-gray-900 mb-5px">
                                            <div
                                                className="progress-bar progress-bar-red rounded-right bg-teal"
                                                data-animation="width"
                                                style={{ width: commonUtils.percent(devicestat.lowbattery, devicestat.totalcnt) + "%" }}
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="table-responsive">
                                                <table className="table table-sm table-centered table-nowrap mb-0">
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">배송 ID</th>
                                                            <th scope="col" className="text-center">
                                                                배송상태
                                                            </th>
                                                            <th scope="col">프로젝트</th>
                                                            <th scope="col">{i18n.t("label_product_nm")}</th>
                                                            <th scope="col">디바이스 ID</th>
                                                            <th scope="col" className="text-center" style={{ width: "150px" }}>
                                                                로깅 시작
                                                            </th>
                                                            <th scope="col" className="text-center" style={{ width: "150px" }}>
                                                                마지막 로깅
                                                            </th>
                                                            <th scope="col" className="text-left">
                                                                {i18n.t("label_status")}
                                                            </th>
                                                            <th scope="col" className="text-center">
                                                                배터리 잔량
                                                            </th>
                                                            <th scope="col" className="text-center">
                                                                담당자
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.devices &&
                                                            apipaginate.map((data) => (
                                                                <tr key={data.deliveryid + data.uuid}>
                                                                    <td>{data.deliveryid}</td>
                                                                    <td className="text-center">
                                                                        <button
                                                                            type="button"
                                                                            className={"btn btn_progress btn-sm status-" + data.status}
                                                                            onClick={() =>
                                                                                this._handleDevice(data.deliveryid, data.location, data.locations, data)
                                                                            }
                                                                        >
                                                                            {i18n.t("label_delivery_status_" + data.status)}
                                                                        </button>
                                                                    </td>
                                                                    <td>{data.projectname}</td>
                                                                    <td>{data.productname}</td>
                                                                    <td>{data.uuid}</td>

                                                                    <td className="text-center" style={{ fontFamily: "verdana", fontSize: "12px" }}>
                                                                        {commonUtils.dateFormat(data.createdate)}
                                                                    </td>
                                                                    <td className="text-center" style={{ fontFamily: "verdana", fontSize: "12px" }}>
                                                                        {commonUtils.dateFormat(data.recentdate)}
                                                                    </td>

                                                                    <td className="text-left">
                                                                        {data.abnormalcnt > 0 ? (
                                                                            <div>
                                                                                <i className="fa fa-circle text-danger f-s-8 mr-2"></i>
                                                                                <span className="text-danger" style={{ fontSize: "12px" }}>
                                                                                    이상 {data.abnormalcnt}회
                                                                                </span>
                                                                            </div>
                                                                        ) : (
                                                                            <div>
                                                                                <i className="fa fa-circle text-success f-s-8 mr-2"></i>
                                                                            </div>
                                                                        )}
                                                                    </td>

                                                                    <td className="text-center">{data.status !== "E" ? data.battery + "V" : ""} </td>
                                                                    <td className="text-center">{data.chargername}</td>
                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                </table>
                                                {this.state.devices.length > 0 && (
                                                    <div className="data_page">
                                                        <Pagination
                                                            activePage={this.state.activePage}
                                                            itemsCountPerPage={20}
                                                            totalItemsCount={this.state.devices.length}
                                                            pageRangeDisplayed={10}
                                                            onChange={this._handlePageChange.bind(this)}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isDevice && (
                    <DeviceMonitoring
                        open={this.state.isDevice}
                        onClose={this._handleClose}
                        deliveryid={this.state.deliveryid}
                        deviceInfo={this.state.deviceInfo}
                        locations={this.state.locations}
                        defaultCenter={this.state.defaultCenter}
                        deliveryInfo={this.state.deliveryInfo}
                    />
                )}
            </Aux>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getDashboard,
            getProjects,
            getProducts,
            dispatch,
        },
        dispatch
    );

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Dashboard);
export { connectedPage as Dashboard };
// export default connect(mapStateToProps, mapDispatchToProps)(windowSize(Dashboard));
