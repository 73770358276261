/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getDeviceStatusLogs } from "_services";
import { DeviceMonitoring } from "_pages";
class DeliveryDevices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deliveryid: "",
            devices: [],
            locations: [],
            defaultCenter: {},
            isDevice: false,
            selectedDevice: "",
        };

        this._handleChange = this._handleChange.bind(this);
        this._getDeviceStatusLogs = this._getDeviceStatusLogs.bind(this);
        this._handleDevice = this._handleDevice.bind(this);
        this._handleClose = this._handleClose.bind(this);
    }

    _handleDevice(device) {
        this.setState({
            isDevice: !this.state.isDevice,
            selectedDevice: device,
        });
    }

    _handleClose() {
        this.setState({
            isDevice: !this.state.isDevice,
            selectedDeviceid: "",
        });
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            ...this.state,
            [name]: value,
        });
    }

    componentDidMount() {
        this._getDeviceStatusLogs();
        this.setState({
            deliveryid: this.props.deliveryid,
            locations: this.props.locations,
            defaultCenter: this.props.defaultCenter,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.deliveryid !== this.props.deliveryid) {
            this.setState({
                deliveryid: this.props.deliveryid,
                locations: this.props.locations,
                defaultCenter: this.props.defaultCenter,
            });
            this._getDeviceStatusLogs();
        }
    }

    _getDeviceStatusLogs() {
        const { deliveryid } = this.props;
        this.props.getDeviceStatusLogs(
            {
                deliveryid: deliveryid,
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        devices: result,
                    });
                }
            }
        );
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-sm table-centered table-nowrap mb-0">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">다비이스ID</th>
                                                <th scope="col">장치명</th>
                                                <th scope="col" className="text-center">
                                                    최근 온도
                                                </th>
                                                <th scope="col" className="text-center" style={{ width: "200px" }}>
                                                    기록 시작
                                                </th>
                                                <th scope="col" className="text-center" style={{ width: "200px" }}>
                                                    최근 기록
                                                </th>
                                                <th scope="col" className="text-left" style={{ width: "150px" }}>
                                                    상태
                                                </th>
                                                <th scope="col" className="text-center" style={{ width: "100px" }}>
                                                    배터리
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.devices &&
                                                this.state.devices.map((data) => (
                                                    <tr
                                                        className="col-12"
                                                        key={data.deviceid}
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => this._handleDevice(data)}
                                                    >
                                                        <td>{data.uuid}</td>
                                                        <td>{data.devicename}</td>
                                                        <td className="text-center">{data.temperature}</td>
                                                        <td className="text-center">{data.createdate}</td>
                                                        <td className="text-center">{data.recentdate}</td>

                                                        <td className="text-left">
                                                            {data.abnormalcnt > 0 ? (
                                                                <div>
                                                                    <i className="fa fa-circle text-danger f-s-8 mr-2"></i>
                                                                    <span className="text-danger" style={{ fontSize: "12px" }}>
                                                                        이상 {data.abnormalcnt}회
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <i className="fa fa-circle text-success f-s-8 mr-2"></i>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td className="text-center">{this.props.deliveryInfo.status !== "E" && data.battery + "V"}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isDevice && (
                    <DeviceMonitoring
                        open={this.state.isDevice}
                        onClose={this._handleClose}
                        deliveryid={this.state.deliveryid}
                        deviceInfo={this.state.selectedDevice}
                        locations={this.state.locations}
                        defaultCenter={this.state.defaultCenter}
                        deliveryInfo={this.props.deliveryInfo}
                    />
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getDeviceStatusLogs,
            dispatch,
        },
        dispatch
    );

const connectedPage = connect(mapStateToProps, mapDispatchToProps)(DeliveryDevices);
export { connectedPage as DeliveryDevices };
// export default connect(mapStateToProps, mapDispatchToProps)(windowSize(DeliveryDevices));
