/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getDeviceLogsByMinute } from "_services";
import { Modal } from "react-bootstrap";
import { Scrollbar } from "react-scrollbars-custom";
import { commonUtils } from "_helpers";
import { DeviceInfo, DeliveryMap, TemperatureChart, ExcelDownload } from "_pages";
// import $ from "jquery";
class DeviceMonitoring extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deliveryid: "",
            locations: [],
            defaultCenter: "",
            deviceInfo: "",
            logs: [],
            totalcnt: "0",
            abnormalcnt: "0",
            searchlogs: [],
            excellogs: [],
            isExcelDownload: false,
            minuteUnit: "10",
            deviceMarkerLoc: "",
        };

        this._handleOnClose = this._handleOnClose.bind(this);
        this._handleDevice = this._handleDevice.bind(this);
        this._handleExcelDownload = this._handleExcelDownload.bind(this);
        this._handleMinute = this._handleMinute.bind(this);
        this._handleLocMarker = this._handleLocMarker.bind(this);
    }
    _handleLocMarker(loc) {
        this.setState({
            ...this.state,
            deviceMarkerLoc: loc,
        });
    }
    _handleMinute(e) {
        this.setState(
            {
                ...this.state,
                minuteUnit: e.target.value,
            },
            () => this._getDeviceLogsByMinute()
        );
    }
    _handleExcelDownload() {
        const logs = [];
        for (let temp of this.state.logs) {
            const logValue = {
                times: temp.sensingtime,
                temperature: temp.temperature,
                tgap: temp.tgap !== null && temp.tgap.split("|")[0],
                updown: (temp.tgap !== null && temp.tgap.split("|")[1]) === "U" ? "▲" : "▼",
                status: temp.tgap !== null ? "이상" : "",
                luxor: temp.luxor,
                humidity: temp.humidity,
                acceleration: temp.acceleration,
                battery: temp.battery,
                loc: temp.loc,
            };
            logs.push(logValue);
        }
        this.setState({
            ...this.state,
            isExcelDownload: !this.state.isExcelDownload,
            excellogs: logs,
        });
    }

    _handleDevice(flag) {
        if (flag === "A") {
            this.setState({
                searchlogs: this.state.logs,
            });
        } else if (flag === "N") {
            this.setState({
                searchlogs: this.state.logs.filter((e) => e.tgap === null),
            });
        } else if (flag === "E") {
            this.setState({
                searchlogs: this.state.logs.filter((e) => e.tgap !== null),
            });
        }
    }

    componentDidMount() {
        // console.log("deliveryid===", this.props.deliveryid);
        // console.log("deliveryInfo===", this.props.deliveryInfo);
        // console.log("deviceInfo===", this.props.deviceInfo);

        this._getDeviceLogsByMinute();
    }

    _handleOnClose() {
        this.props.onClose();
    }

    _getDeviceLogsByMinute() {
        this.props.getDeviceLogsByMinute(
            {
                deliveryid: this.props.deliveryid,
                devicename: this.props.deviceInfo.devicename,
                minuteUnit: this.state.minuteUnit,
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        logs: result,
                        searchlogs: result.reverse(),
                        totalcnt: result.length,
                        abnormalcnt: result.filter((e) => e.tgap !== null).length,
                    });
                }
            }
        );
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={() => {
                        this._handleOnClose();
                    }}
                    dialogClassName="fullscreen-modal modal-dialog modal-dialog-centered"
                    backdrop="static"
                >
                    <div className="modal-header">
                        <h5 className="modal-title">디바이스 ID : {this.props.deviceInfo.uuid}</h5>
                        <select
                            className="form-control"
                            name="minuteUnit"
                            value={this.state.minuteUnit}
                            onChange={(e) => this._handleMinute(e)}
                            style={{ width: "200px", margin: "0px 0px 0px 30px" }}
                        >
                            <option value="">측정 단위</option>
                            <option value="5">5분</option>
                            <option value="10">10분</option>
                            <option value="15">15분</option>
                            <option value="20">20분</option>
                            <option value="30">30분</option>
                        </select>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                                this._handleOnClose();
                            }}
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div className="row">
                            {this.props.deliveryInfo && (
                                <DeviceInfo
                                    deliveryInfo={this.props.deliveryInfo}
                                    deviceInfo={this.props.deviceInfo}
                                    totalcnt={this.state.totalcnt}
                                    abnormalcnt={this.state.abnormalcnt}
                                    locations={this.props.locations}
                                />
                            )}
                        </div>
                        {this.props.locations.length > 0 && (
                            <DeliveryMap
                                deliveryid={this.props.deliveryid}
                                locations={this.props.locations}
                                defaultCenter={this.props.defaultCenter}
                                markerLoc={this.state.deviceMarkerLoc}
                            />
                        )}

                        {this.state.searchlogs.length > 0 && (
                            <div className="card">
                                <div className="card-body">
                                    <TemperatureChart logs={this.state.logs} />
                                </div>
                            </div>
                        )}

                        <div className="row">
                            <div className="col-6">
                                <div className="text-sm-left device-label">측정 데이터(10분 단위 기록)</div>
                            </div>
                            <div className="col-6">
                                <div className="text-sm-right">
                                    <button type="button" className="btn btn-date" onClick={() => this._handleDevice("A")}>
                                        전체 &nbsp;&nbsp;<span className="temp-up"> {this.state.totalcnt}</span>
                                    </button>
                                    &nbsp;
                                    <button type="button" className="btn btn-date" onClick={() => this._handleDevice("N")}>
                                        정상 &nbsp;&nbsp;<span className="temp-up"> {this.state.totalcnt - this.state.abnormalcnt}</span>
                                    </button>
                                    &nbsp;
                                    <button type="button" className="btn btn-date" onClick={() => this._handleDevice("E")}>
                                        이상 &nbsp;&nbsp;<span className="temp-up"> {this.state.abnormalcnt} + </span>
                                    </button>
                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                    <button type="button" className="btn btn-info" onClick={() => this._handleExcelDownload()}>
                                        <i className="bx bx-download"></i> 다운로드
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{ marginTop: "5px" }}>
                            <div className="col-12">
                                <Scrollbar style={{ height: 300 }}>
                                    <table className="table table-sm table-centered table-nowrap">
                                        <thead className="thead-light">
                                            <tr style={{ height: "25px" }}>
                                                <th>기록시간</th>
                                                <th>위치</th>
                                                <th>온도(°C)</th>
                                                <th>습도(%)</th>
                                                <th>충격(G)</th>
                                                <th>조도(lx)</th>
                                                <th>상태</th>
                                                <th>배터리(V)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.searchlogs &&
                                                this.state.searchlogs.map((data) => (
                                                    <tr className="col-12" key={data.sensingdate}>
                                                        <td>{data.sensingtime}</td>
                                                        <td>
                                                            <span style={{ cursor: "pointer" }} onClick={() => this._handleLocMarker(data.loc)}>
                                                                {data.loc}
                                                            </span>
                                                        </td>
                                                        <td style={{ display: "flex" }}>
                                                            <span style={{ padding: "0px 20px 0px 0px", width: "70px" }}>{data.temperature}</span>
                                                            {data.tgap !== null && data.tgap.split("|")[1] === "D" && (
                                                                <span className="temp-down">{data.tgap !== null ? data.tgap.split("|")[0] : ""} &nbsp; ▼</span>
                                                            )}
                                                            {data.tgap !== null && data.tgap.split("|")[1] === "U" && (
                                                                <span className="temp-up">{data.tgap !== null ? data.tgap.split("|")[0] : ""} &nbsp; ▲</span>
                                                            )}
                                                            {data.tgap === null && <span> - </span>}
                                                        </td>
                                                        <td>{data.humidity}</td>
                                                        <td>{data.acceleration}</td>
                                                        <td>{data.luxor}</td>
                                                        <td>
                                                            {data.tgap !== null ? (
                                                                <div>
                                                                    <i className="fa fa-circle text-danger f-s-8 mr-2" style={{ fontSize: "11px" }}></i>
                                                                    <span className="temp-up"> 이상</span>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <i className="fa fa-circle text-success f-s-8 mr-2" style={{ fontSize: "11px" }}></i>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td>{data.battery}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </Scrollbar>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer"></div>
                </Modal>
                {this.state.isExcelDownload && (
                    <ExcelDownload logs={this.state.excellogs} deviceInfo={this.props.deviceInfo} deliveryInfo={this.props.deliveryInfo} />
                )}
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getDeviceLogsByMinute,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(DeviceMonitoring);
export { connectedPage as DeviceMonitoring };
