/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getSensors, getDeliveryRamainDevices, setDeliveryDeviceMatch, deleteDeliveryDevice, getProducts, addDelivery } from "_services";
import { openAlertDialog } from "../../_actions";
import { Modal } from "react-bootstrap";
import { Scrollbar } from "react-scrollbars-custom";
import i18n from "../../i18n";
import $ from "jquery";
class Delivery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            deliveryid: "",
            searchStr: "",
            sensortype: "A",
            devices: [],
            searchDevices: [],
            selectedDevices: [],
            sensors: [],
            projectid: "A",
            productid: "A",
            projects: [],
            products: [],
            shippingaddress: "",
            status: "T",
        };

        this._handleOnClose = this._handleOnClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleRequest = this._handleRequest.bind(this);
        this._getSensors = this._getSensors.bind(this);
        this._getDevices = this._getDevices.bind(this);
        this._handleSelectChange = this._handleSelectChange.bind(this);
        this._handleSearchClickChange = this._handleSearchClickChange.bind(this);
        this._handleChangeDevice = this._handleChangeDevice.bind(this);
        this._getProducts = this._getProducts.bind(this);
        this._handleSensorChange = this._handleSensorChange.bind(this);
        this._handleShipping = this._handleShipping.bind(this);
        this._handleClose = this._handleClose.bind(this);
    }

    _handleShipping(e) {
        this.setState({
            shippingaddress: e.target.value,
        });
    }

    _handleChangeDevice(seldevice, flag) {
        if (flag === "minus") {
            $("#" + seldevice.deviceid).prop("checked", false);
            if (seldevice.realyn === "Y") {
                this.props.deleteDeliveryDevice({ deliveryid: this.props.deliveryid, deviceid: seldevice.deviceid }, (error, result) => {
                    if (result) {
                        this._getDevices();
                        this.setState({
                            selectedDevices: result,
                        });
                    }
                });
            }
        }

        let newDevices = [...this.state.selectedDevices];
        let deviceid = this.state.selectedDevices.some((data) => data["deviceid"] === seldevice.deviceid);
        if (deviceid) {
            newDevices = newDevices.filter((data) => data.deviceid !== seldevice.deviceid);
        } else {
            newDevices.push(seldevice);
        }

        this.setState({
            selectedDevices: newDevices,
        });
    }

    _handleSearchClickChange() {
        this.setState({ searchDevices: this.state.devices });
    }

    _handleSelectChange(e, searchFlag) {
        this.setState(
            {
                ...this.state,
                [searchFlag]: e.target.value,
                productid: searchFlag === "projectid" ? "A" : e.target.value,
            },
            () => {
                if (searchFlag === "projectid") this._getProducts();
            }
        );
    }

    _handleSensorChange(e) {
        this.setState(
            {
                ...this.state,
                sensortype: e.target.value,
            },
            () => {
                this._getDevices();
            }
        );
    }

    _handleRequest(e, status) {
        e.preventDefault();
        const { projectid, productid, selectedDevices } = this.state;
        if (projectid === "A" || !projectid) {
            this.props.dispatch(openAlertDialog("프로젝트를 선택하세요!", false, "check", false, null, null, null, null));
            return;
        }
        if (productid === "A" || !productid) {
            this.props.dispatch(openAlertDialog("상품을 선택하세요!", false, "check", false, null, null, null, null));
            return;
        }

        if (selectedDevices.length <= 0) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_device_empty"), false, "check", false, null, null, null, null));
            return;
        }

        const session = JSON.parse(localStorage.getItem("session"));

        const delivery = {
            projectid: projectid,
            productid: productid,
            shippingaddress: this.state.shippingaddress,
            devices: selectedDevices,
            status: status,
            userid: session.userid,
        };

        this.props.addDelivery(delivery, (error, result) => {
            if (result) {
                this.props.dispatch(openAlertDialog("배송등록이 완료되었습니다. 자동으로 배송이 시작됩니다.", false, "check", false, null, null, null, null));

                this._handleClose();
            }
        });
    }

    _handleClose() {
        this.props.onSave();
    }

    _handleChange(e) {
        let searchList = this.state.devices;
        searchList = searchList.filter(function (item) {
            return item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1;
        });

        this.setState({ ...this.state, searchDevices: searchList });
    }

    componentDidMount() {
        const { projects, projectid, productid } = this.props;

        this.setState({
            ...this.state,
            projects: projects ? projects : [],
            projectid: projectid ? projectid : "A",
            productid: productid ? productid : "A",
        });

        this._getDevices();
        this._getSensors();
    }

    _handleOnClose() {
        this.props.onClose();
    }

    _getDevices() {
        const { sensortype, searchStr } = this.state;
        this.props.getDeliveryRamainDevices(
            {
                sensortype: sensortype,
                searchStr: searchStr,
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        devices: result,
                        searchDevices: result,
                    });
                }
            }
        );
    }

    _getSensors() {
        this.props.getSensors({}, (error, result) => {
            if (result) {
                this.setState({
                    ...this.state,
                    sensors: result,
                });
            }
        });
    }

    _getProducts() {
        const { projectid } = this.state;
        this.props.getProducts(
            {
                searchStr: "",
                projectid: projectid,
                categoryid: "A",
            },
            (error, result) => {
                if (result) {
                    this.setState({
                        ...this.state,
                        products: result,
                    });
                }
            }
        );
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={() => {
                        this._handleOnClose();
                    }}
                    dialogClassName="modal-dialog modal-dialog-centered  modal-xl"
                    backdrop="static"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">배송 설정</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <form className="form-horizontal">
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group row">
                                            <label className="required col-form-label col-sm-2">프로젝트</label>
                                            <div className="col-sm-10">
                                                <select
                                                    className="form-control"
                                                    name="projectid"
                                                    value={this.state.projectid}
                                                    onChange={(e) => this._handleSelectChange(e, "projectid")}
                                                    style={{ width: "300px" }}
                                                >
                                                    <option value="A">프로젝트 선택</option>
                                                    {this.state.projects &&
                                                        this.state.projects.map((data, index) => (
                                                            <option key={index} value={data.projectid}>
                                                                {data.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group row">
                                            <label className="required col-form-label col-sm-2">상품</label>
                                            <div className="col-sm-10">
                                                <select
                                                    className="form-control"
                                                    name="productid"
                                                    value={this.state.productid}
                                                    style={{ width: "300px" }}
                                                    onChange={(e) => this._handleSelectChange(e, "productid")}
                                                >
                                                    <option value="A">상품 선택</option>
                                                    {this.state.products &&
                                                        this.state.products.map((data) => (
                                                            <option key={data.productid} value={data.productid}>
                                                                {data.name}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group row">
                                            <label className="required col-sm-2 col-form-label" htmlFor="shippingaddress">
                                                납품장소
                                            </label>
                                            <div className="col-sm-10">
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={this.state.shippingaddress}
                                                    onChange={this._handleShipping}
                                                    name="shippingaddress"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <div className="row" style={{ marginTop: "20px" }}>
                                <div className="col-6">
                                    <div className="form_box" style={{ marginBottom: "5px" }}>
                                        <div className="form-group">
                                            <select
                                                className="form-control "
                                                name="sensortype"
                                                value={this.state.sensortype}
                                                onChange={this._handleSensorChange}
                                            >
                                                <option value="A">모델명</option>
                                                {this.state.sensors &&
                                                    this.state.sensors.map((data) => (
                                                        <option key={data.sensorid} value={data.name}>
                                                            {data.name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                        &nbsp;
                                        <div className="input-group" style={{ width: "50%" }}>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="장치명"
                                                onClick={this._handleSearchClickChange}
                                                onChange={this._handleChange}
                                            />
                                        </div>
                                    </div>
                                    <Scrollbar style={{ height: 600 }}>
                                        <table className="table table-sm table-centered table-nowrap">
                                            <thead className="thead-light">
                                                <tr style={{ height: "25px" }}>
                                                    <th style={{ width: "30px" }}></th>
                                                    <th>모델명</th>
                                                    <th>장치명</th>
                                                    <th>디바이스 ID</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.searchDevices &&
                                                    this.state.searchDevices.map((data) => (
                                                        <tr className="col-12" key={data.deviceid}>
                                                            <td>
                                                                <input
                                                                    className="form-control"
                                                                    style={{ width: "17px" }}
                                                                    type="checkbox"
                                                                    id={data.deviceid}
                                                                    value={data.deviceid}
                                                                    name="devices"
                                                                    onChange={() => this._handleChangeDevice(data, "plus")}
                                                                />
                                                            </td>
                                                            <td>{data.sensortype}</td>
                                                            <td>{data.name}</td>
                                                            <td>{data.uuid}</td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </Scrollbar>
                                </div>
                                <div className="col-6">
                                    <Scrollbar style={{ height: 600 }}>
                                        <table className="table table-sm table-centered table-nowrap" style={{ marginTop: "35px" }}>
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>{i18n.t("label_sensor_type")}</th>
                                                    <th>이름</th>
                                                    <th>UUID</th>
                                                    <th style={{ width: "60px" }} className="text-cener">
                                                        {i18n.t("label_btn_delete")}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.selectedDevices &&
                                                    this.state.selectedDevices.map((data) => (
                                                        <tr key={data.deviceid} style={{ height: "28px" }}>
                                                            <td>{data.sensortype}</td>
                                                            <td>{data.name}</td>
                                                            <td>{data.uuid}</td>
                                                            <td className="text-cener">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-danger btn-sm"
                                                                    onClick={() => this._handleChangeDevice(data, "minus")}
                                                                >
                                                                    <i className="bx bx-minus"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </Scrollbar>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {/* <button
                                type="button"
                                className="btn btn-danger"
                                data-dismiss="modal"
                                onClick={(e) => {
                                    this._handleRequest(e, "S");
                                }}
                            >
                                배송시작
                            </button> */}

                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={(e) => {
                                    this._handleRequest(e, "0");
                                }}
                            >
                                등록
                            </button>

                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                {i18n.t("label_btn_cancel")}
                            </button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getSensors,
            getDeliveryRamainDevices,
            setDeliveryDeviceMatch,
            deleteDeliveryDevice,
            getProducts,
            addDelivery,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Delivery);
export { connectedPage as Delivery };
