import {
    DeviceManagement,
    UserManagement,
    ProjectManagement,
    ProductManagement,
    DeliveryManagement,
    DeliveryMonitoring,
    Dashboard,
    DeviceCurrents,
} from "_pages";

export const routes = [
    {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        exact: true,
    },
    {
        path: "/device-monitoring",
        name: "Device Monitoring",
        component: DeviceCurrents,
        exact: true,
    },
    {
        path: "/project",
        name: "Project Management",
        component: ProjectManagement,
        exact: true,
    },
    {
        path: "/user",
        name: "User Management",
        component: UserManagement,
        exact: true,
    },
    {
        path: "/product",
        name: "Product Management",
        component: ProductManagement,
        exact: true,
    },
    {
        path: "/device",
        name: "Device Management",
        component: DeviceManagement,
        exact: true,
    },
    {
        path: "/deliveries",
        name: "DeliveryManagement Management",
        component: DeliveryManagement,
        exact: true,
    },
    {
        path: "/monitoring",
        name: "DeliveryMonitoring",
        component: DeliveryMonitoring,
        exact: true,
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Dashboard,
        exact: true,
    },
];
