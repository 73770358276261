/* eslint-disable no-extend-native */
import i18n from "../../i18n";
const menus = [
    {
        id: "dashboard",
        title: "홈",
        type: "item",
        icon: "bx bx-home",
        path: "/dashboard",
        class: "dashboard",
        show: true,
    },
    {
        id: "deliveries",
        title: "배송 현황",
        type: "item",
        path: "/deliveries",
        icon: "bx bx-bus",
        class: "project",
        show: true,
    },
    {
        id: "device-monitoring",
        title: "디바이스 현황",
        type: "item",
        path: "/device-monitoring",
        icon: "bx bx-devices",
        class: "project",
        show: true,
    },
];

const supermenu = [
    {
        id: "project",
        title: "프로젝트 관리",
        type: "item",
        path: "/project",
        icon: "bx bx-minus",
        class: "project",
        child: "Y",
        show: true,
    },

    {
        id: "product",
        title: "상품 관리",
        type: "item",
        icon: "bx bx-minus",
        path: "/product",
        class: "product",
        child: "Y",
        show: true,
    },

    {
        id: "device",
        title: "디바이스 관리",
        type: "item",
        icon: "bx bx-minus",
        path: "/device",
        class: "device",
        child: "Y",
        show: true,
    },
    {
        id: "user",
        title: "사용자 관리",
        type: "item",
        path: "/user",
        icon: "bx bx-minus",
        class: "user_device",
        child: "Y",
        show: true,
    },
];

export function getMenus() {
    const menuList = menus;
    return menuList;
}

export function getSuperMenus() {
    const menuList = supermenu;
    return menuList;
}
