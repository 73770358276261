/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { insertDevice, deleteDevice, updateDevice } from "_services";
import { openAlertDialog } from "_actions";
import { Modal } from "react-bootstrap";
import i18n from "../../i18n";
class Device extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sensortype: "",
            deviceid: "",
            uuid: "",
            name: "",
            mintemperature: "-15",
            maxtemperature: "60",
            minhumidity: "0",
            maxhumidity: "100",
            maxluxor: "100000",
            maxacceleration: "12",
            measurementterm: "5",
        };

        this._handleOnClose = this._handleOnClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
        this._handleRequest = this._handleRequest.bind(this);
        this._handleClose = this._handleClose.bind(this);
        this._handleDeleteRequest = this._handleDeleteRequest.bind(this);
    }

    _handleRequest(e) {
        e.preventDefault();
        const { sensortype, name } = this.state;
        if (!name) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_devicenm"), false, "check", false, null, null, null, null));
            return;
        }

        if (!sensortype) {
            this.props.dispatch(openAlertDialog(i18n.t("msg_alert_sensortype"), false, "check", false, null, null, null, null));
            return;
        }

        const device = {
            sensortype: this.state.sensortype,
            deviceid: this.state.deviceid,
            name: this.state.name,
            uuid: this.state.uuid,
            mintemperature: this.state.mintemperature,
            maxtemperature: this.state.maxtemperature,
            minhumidity: this.state.minhumidity,
            maxhumidity: this.state.maxhumidity,
            // minluxor: this.state.minluxor,
            maxluxor: this.state.maxluxor,
            maxacceleration: this.state.maxacceleration,
            measurementterm: this.state.measurementterm,
        };

        if (this.props.device) {
            this.props.updateDevice(device, (error, result) => {
                if (result) {
                    this._handleClose();
                }
            });
        } else {
            this.props.insertDevice(device, (error, result) => {
                if (result) {
                    this._handleClose();
                }
            });
        }
    }

    _handleDeleteRequest(e) {
        e.preventDefault();
        if (this.state.deviceid) {
            this.props.deleteDevice({ deviceid: this.state.deviceid }, (error, result) => {
                if (result) {
                    this._handleClose();
                }
            });
        } else {
            this._handleClose();
        }
    }

    _handleClose() {
        this.props.onSave();
    }

    _handleChange(e) {
        const { name, value } = e.target;
        this.setState({
            [name]: value,
        });
    }

    componentDidMount() {
        const { device } = this.props;
        if (device) {
            this.setState({
                ...this.state,
                sensortype: device.sensortype,
                deviceid: device.deviceid,
                uuid: device.uuid,
                name: device.name,
                mintemperature: device.mintemperature,
                maxtemperature: device.maxtemperature,
                minhumidity: device.minhumidity,
                maxhumidity: device.maxhumidity,
                // minluxor: device.minluxor,
                maxluxor: device.maxluxor,
                maxacceleration: device.maxacceleration,
                measurementterm: device.measurementterm,
            });
        } else {
            this.setState({
                ...this.state,
                sensortype: this.props.sensortype,
            });
        }
    }

    _handleOnClose() {
        this.props.onClose();
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={() => {
                        this._handleOnClose();
                    }}
                    dialogClassName="modal-dialog modal-dialog-centered sz_xlg"
                    backdrop="static"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">디바이스</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <form className="form-horizontal">
                                <div className="form-group">
                                    <label className="required">장치명</label>
                                    <input className="form-control " type="text" value={this.state.name} onChange={this._handleChange} name="name" />
                                </div>

                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label className="required">{i18n.t("label_sensor_type")}</label>
                                        <select className="form-control" name="sensortype" value={this.state.sensortype} onChange={this._handleChange}>
                                            <option value="">모델명</option>
                                            {this.props.sensors &&
                                                this.props.sensors.map((data) => (
                                                    <option key={data.sensorid} value={data.name}>
                                                        {data.name}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <div className="form-group">
                                            <label>디바이스 ID</label>
                                            <input className="form-control " type="text" value={this.state.uuid} onChange={this._handleChange} name="uuid" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="required">{i18n.t("label_device_measure_term_desc")}</label>
                                    <input
                                        className="form-control "
                                        type="text"
                                        value={this.state.measurementterm}
                                        onChange={this._handleChange}
                                        name="measurementterm"
                                        style={{ width: "260px" }}
                                    />
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>{i18n.t("label_device_temperature")}</label>(Min)
                                        <input
                                            className="form-control "
                                            type="text"
                                            value={this.state.mintemperature}
                                            onChange={this._handleChange}
                                            name="mintemperature"
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>{i18n.t("label_device_temperature")}</label>(Max)
                                        <input
                                            className="form-control "
                                            type="text"
                                            value={this.state.maxtemperature}
                                            onChange={this._handleChange}
                                            name="maxtemperature"
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>{i18n.t("label_device_humididy")}</label>(Min, 최소 0%)
                                        <input
                                            className="form-control "
                                            type="text"
                                            value={this.state.minhumidity}
                                            onChange={this._handleChange}
                                            name="minhumidity"
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>{i18n.t("label_device_humididy")}</label>(Max, 최대 100%)
                                        <input
                                            className="form-control "
                                            type="text"
                                            value={this.state.maxhumidity}
                                            onChange={this._handleChange}
                                            name="maxhumidity"
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label>{i18n.t("label_device_lux")}</label>(Max, 최대 100,000lux)
                                        <input
                                            className="form-control "
                                            type="text"
                                            value={this.state.maxluxor}
                                            onChange={this._handleChange}
                                            name="maxluxor"
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label>{i18n.t("label_device_acceleration")}</label>(Max, 최대 12G)
                                        <input
                                            className="form-control "
                                            type="text"
                                            value={this.state.maxacceleration}
                                            onChange={this._handleChange}
                                            name="maxacceleration"
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={(e) => {
                                    this._handleRequest(e);
                                }}
                            >
                                {i18n.t("label_btn_save")}
                            </button>
                            {this.props.device && (
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    data-dismiss="modal"
                                    onClick={(e) => {
                                        this._handleDeleteRequest(e);
                                    }}
                                >
                                    {i18n.t("label_btn_delete")}
                                </button>
                            )}
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                {i18n.t("label_btn_cancel")}
                            </button>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            insertDevice,
            deleteDevice,
            updateDevice,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(Device);
export { connectedPage as Device };
