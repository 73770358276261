/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getDeliveryDeviceLogDatas } from "_services";
import ReactEcharts from "echarts-for-react";
import { TemperatureLog, ShockLog, LuxLog, TemperatureChart, ShockChart, LuxChart } from "_pages";
import echarts from "echarts";
import { Modal } from "react-bootstrap";
import i18n from "../../i18n";
class DeliveryDeviceChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            logs: [],
            viewType: "T",
        };

        this._handleOnClose = this._handleOnClose.bind(this);
        this._handleChange = this._handleChange.bind(this);
    }

    _handleClose() {
        this.props.onSave();
    }

    _handleChange(flag) {
        this.setState({
            viewType: flag,
        });
    }

    componentDidMount() {
        // console.log("this.props.logs", this.props.logs);
    }

    _handleOnClose() {
        this.props.onClose();
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.open}
                    onHide={() => {
                        this._handleOnClose();
                    }}
                    dialogClassName="modal-dialog modal-dialog-centered modal-lg"
                    backdrop="static"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{this.props.devicename}</h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    this._handleOnClose();
                                }}
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body mt-2">
                            {this.state.viewType === "T" && <TemperatureChart logs={this.props.logs} />}
                            {this.state.viewType === "S" && <ShockChart logs={this.props.logs} />}
                            {this.state.viewType === "L" && <LuxChart logs={this.props.logs} />}

                            <div style={{ padding: "10px 10px 0px 10px" }}>
                                <ul className="nav nav-pills mb-2">
                                    <li className="nav-item">
                                        <a href="#nav-pills-tab-1" data-toggle="tab" className="nav-link active" onClick={() => this._handleChange("T")}>
                                            <span className="d-sm-block d-none">온도</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#nav-pills-tab-2" data-toggle="tab" className="nav-link" onClick={() => this._handleChange("S")}>
                                            <span className="d-sm-block d-none">충격</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a href="#nav-pills-tab-3" data-toggle="tab" className="nav-link" onClick={() => this._handleChange("L")}>
                                            <span className="d-sm-block d-none">조도</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content p-15 rounded bg-white">
                                <div style={{ padding: "0px 10px 0px 10px" }} className="tab-pane fade active show" id="nav-pills-tab-1">
                                    <TemperatureLog logs={this.props.logs} />
                                </div>
                                <div style={{ padding: "0px 10px 0px 10px" }} className="tab-pane fade" id="nav-pills-tab-2">
                                    <ShockLog logs={this.props.logs} />
                                </div>
                                <div style={{ padding: "0px 10px 0px 10px" }} className="tab-pane fade" id="nav-pills-tab-3">
                                    <LuxLog logs={this.props.logs} />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer"></div>
                    </div>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getDeliveryDeviceLogDatas,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(DeliveryDeviceChart);
export { connectedPage as DeliveryDeviceChart };
