export const ALERT_OPEN = "ALERT_OPEN";
export const ALERT_CLOSE = "ALERT_CLOSE";
export const ALERT_NONE = "ALERT_NONE";

export function openAlertDialog(message, title, icon, confirm, labelOk, labelNo, handleOk, handleNo) {
    if (!message) {
        return { type: ALERT_NONE };
    } else {
        return {
            type: ALERT_OPEN, //
            message,
            title,
            icon,
            confirm,
            labelOk,
            labelNo,
            handleOk,
            handleNo
        };
    }
}

export function closeAlertDialog() {
    return { type: ALERT_CLOSE };
}
