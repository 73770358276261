import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactExport from "react-data-export";

import moment from "moment";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

// const multiDataSet = [
//     {
//         xSteps: 1,
//         ySteps: 5,
//         columns: ["Name", "Department"],
//         data: [
//             ["Johnson", "Finance"],
//             ["Monika", "IT"],
//             ["Konstantina", "IT Billing"],
//             ["John", "HR"],
//             ["Josef", "Testing"],
//         ],
//     },
//     {
//         columns: ["기록시간", "온도(°C)", "온도이상", "습도(%)", "조도(lx)", "충격(G)", "상태", "배터리(V)"],
//         data: (function () {
//             let res = [];

//             for (let temp of this.props.logs) {
//                 res.push(temp.times, temp.temperature, temp.tgap);
//             }
//             return res;
//         })(),
//     },
// ];

class ExcelDownload extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <ExcelFile hideElement="true" filename={this.props.deviceInfo.devicename + "_" + moment().format("YYYYMMDDHHmmss")}>
                    <ExcelSheet data={this.props.logs} name={this.props.deviceInfo.devicename}>
                        {/* <ExcelSheet dataSet={multiDataSet} name="Organization" /> */}
                        <ExcelColumn label="기록시간" value="times" />
                        <ExcelColumn label="위치" value="loc" />
                        <ExcelColumn label="온도(°C)" value="temperature" />
                        <ExcelColumn label="온도이상" value={(params) => params.tgap + params.updown} />
                        <ExcelColumn label="습도(%)" value="humidity" />
                        <ExcelColumn label="조도(lx)" value="luxor" />
                        <ExcelColumn label="충격(G)" value="acceleration" />
                        <ExcelColumn label="상태" value="status" />
                        <ExcelColumn label="배터리(V)" value="battery" />
                    </ExcelSheet>
                </ExcelFile>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(ExcelDownload);
export { connectedPage as ExcelDownload };
