/* eslint-disable react/jsx-pascal-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getDeliveryDeviceLogDatas } from "_services";
import ReactEcharts from "echarts-for-react";
import { TemperatureLog, ShockLog, LuxLog } from "_pages";
import echarts from "echarts";
import { Modal } from "react-bootstrap";
import i18n from "../../i18n";
class ShockChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            option: this.getOption(this.props.logs),
        };
    }

    getOption = (temperatures) => {
        let seriesData = [];
        for (let temp of temperatures) {
            let res = [];
            res.push(temp.measuringtime, temp.sgap);
            seriesData.push(res);
        }

        const option = {
            title: {},
            animation: true,
            tooltip: {
                trigger: "axis",
            },
            grid: {
                top: 30,
                left: 20,
                right: 20,
                bottom: 40,
            },
            xAxis: [
                {
                    data: (function () {
                        let res = [];
                        for (let temp of temperatures) {
                            res.push(temp.measuringtime);
                        }
                        return res;
                    })(),
                    boundaryGap: false,
                },
            ],
            yAxis: [
                {
                    type: "value",
                    name: "충격",
                    axisLabel: {
                        inside: true,
                        formatter: "{value} G",
                    },
                    min: function (item) {
                        var diff = item.max - item.min;
                        if (diff === 0) {
                            diff = 1;
                        }
                        return (item.min - diff * 0.2).toFixed(2);
                    },
                    splitArea: {
                        show: true,
                        areaStyle: {
                            opacity: 0.1,
                        },
                    },
                },
                {
                    type: "value",
                    name: "배터리",
                    min: 0,
                    max: 100,
                    interval: 50,
                    axisLabel: {
                        inside: true,
                        formatter: "{value} V",
                    },
                },
            ],

            series: [
                {
                    type: "line",
                    yAxisIndex: 0,
                    connectNulls: true,
                    symbolSize: 10,
                    lineStyle: {
                        normal: {
                            color: "#ea6f21",
                            width: 4,
                        },
                    },
                    tooltip: {
                        show: true,
                    },
                    areaStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    {
                                        offset: 0,
                                        color: "rgba(236, 169, 44, 1)",
                                    },
                                    {
                                        offset: 1,
                                        color: "rgba(236, 169, 44,0)",
                                    },
                                ],
                                false
                            ),
                        },
                    },
                    markPoint: {
                        data: [
                            { type: "max", name: "max" },
                            { type: "min", name: "min" },
                        ],
                    },
                    markLine: {
                        data: [{ type: "average", name: "avg" }],
                    },
                    data: (function () {
                        let res = [];

                        for (let temp of temperatures) {
                            res.push(temp.sgap);
                        }
                        return res;
                    })(),
                },
                {
                    type: "line",
                    yAxisIndex: 1,
                    connectNulls: true,
                    data: (function () {
                        let res = [];

                        for (let temp of temperatures) {
                            res.push(temp.battery);
                        }
                        return res;
                    })(),
                },
            ],
        };

        return option;
    };

    componentDidMount() {}

    render() {
        return (
            <>
                <ReactEcharts notMerge ref="echarts_react" option={this.state.option} style={{ height: 300 }} />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {};
}
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getDeliveryDeviceLogDatas,
            dispatch,
        },
        dispatch
    );
const connectedPage = connect(mapStateToProps, mapDispatchToProps)(ShockChart);
export { connectedPage as ShockChart };
